import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import {
  LiaTimesCircleSolid,
  FaCalendarAlt,
  FiEye,
  FaFilePdf,
  FaPrescription,
} from "../../assets/icons/vander";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import client1 from "../../assets/images/client/01.jpg";
import jsPDF from "jspdf"; // PDF library
import "jspdf-autotable"; // Plugin for tables in PDF
import { Tooltip, OverlayTrigger } from "react-bootstrap"; // Added import
import PrescriptionHistory from "./PrescriptionHistory";
import axios from "axios";
import {
  convertTo12HourFormat,
  getMaxDate,
} from "../../components/CommonComponents/CommonFunction";
import DatePicker from "react-datepicker";
import { TextField } from "@mui/material";

export default function Crud({ appointment, allPrescription, fetchData }) {
  const [show, setShow] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [showPrescription, setShowPrescription] = useState(false);
  const [showPrescriptionHistory, setShowPrescriptionHistory] = useState(false);
  const [newDate, setNewDate] = useState();
  const [newTime, setNewTime] = useState();
  const [loadingAction, setLoadingAction] = useState(null);
  // const [prescription, setPrescription] = useState(null);
  // const [prescriptionHistory, setPrescriptionHistory] = useState([]);
  const token = localStorage.getItem("accessToken");
  const [firstRender, setFirstRender] = useState(false);
  const [availableDays, setAvailableDays] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const baseURL = process.env.REACT_APP_API_URL;
  const doctorId = appointment?.doctor;




  useEffect(() => {
    if (showReschedule) {
      const fetchTimetablesAndAppointment = async () => {
        setLoading(true);
        try {
          // Fetch doctor's timetable
          const doctorResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/doctors/details/${doctorId}/`
          );
  
          if (!doctorResponse.ok) {
            throw new Error("Network response was not ok");
          }
  
          const doctorData = await doctorResponse.json();
          const clinicId = appointment.clinic_id; // Get the clinic ID from appointment
  
          let availableDaysFiltered = [];
  
          // Check if clinicId is defined and has a valid value
          if (clinicId) {
            // If clinic ID exists and is valid, filter by matching clinic ID
            availableDaysFiltered = doctorData.timetables
              .filter(timetable => timetable.clinic === clinicId) // Match on clinic ID
              .map(timetable => timetable.day_of_week);
          } else {
            // If clinic ID is not defined, fallback to all available days
            availableDaysFiltered = doctorData.timetables
              .map(timetable => timetable.day_of_week)
              .filter(day => day);
          }
  
          setAvailableDays(availableDaysFiltered);
          // Optionally set the clinic list if needed
          setClinicList(
            doctorData.timetables.map(timetable => ({
              clinic_name: timetable.clinic_name,
              clinic_address: timetable.clinic_address,
            }))
          );
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };
  
      fetchTimetablesAndAppointment();
    }
  }, [showReschedule, doctorId, appointment]);
  


  // useEffect(() => {
  //   if (showReschedule) {
  //     const fetchTimetablesAndAppointment = async () => {
  //       setLoading(true);
  //       try {
  //         // Fetch doctor's timetable
  //         const doctorResponse = await fetch(
  //           `${process.env.REACT_APP_API_URL}/doctors/details/${doctorId}/`
  //         );
  
  //         if (!doctorResponse.ok) {
  //           throw new Error("Network response was not ok");
  //         }
  
  //         const doctorData = await doctorResponse.json();
  //         const clinicDetails = appointment.clinic_id;
  // console.log("clinicDetails",clinicDetails)
  //         // Filter available days based on matching clinic ID
  //         const availableDaysFiltered = doctorData.timetables
  //           .filter(timetable =>
  //             timetable.clinic === clinicDetails // Match on clinic ID
  //           )
  //           .map(timetable => timetable.day_of_week);
  
  //         setAvailableDays(availableDaysFiltered);
  //       } catch (error) {
  //         setError(error.message);
  //       } finally {
  //         setLoading(false);
  //       }
  //     };
  
  //     fetchTimetablesAndAppointment();
  //   }
  // }, [showReschedule, doctorId, appointment]);
  // useEffect(() => {
  //   if (showReschedule) {
  //     const fetchTimetables = async () => {
  //       setLoading(true);
  //       try {
  //         const response = await fetch(
  //           `${process.env.REACT_APP_API_URL}/doctors/details/${doctorId}/`
  //         );
  //         if (!response.ok) {
  //           throw new Error("Network response was not ok");
  //         }
  //         const data = await response.json();

  //         const availableDays = data.timetables
  //           .map((timetable) => timetable.day_of_week)
  //           .filter((day) => day);

  //         setAvailableDays(availableDays);
  //         setClinicList(
  //           data.timetables.map((timetable) => ({
  //             clinic_name: timetable.clinic_name,
  //             clinic_address: timetable.clinic_address,
  //           }))
  //         );
  //       } catch (error) {
  //         setError(error.message);
  //       } finally {
  //         setLoading(false);
  //       }
  //     };
  //     fetchTimetables();
  //   }
  // }, [showReschedule, doctorId]);
  const fetchUnavailableDates = async (doctorId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/unavailable-dates/?doctor_id=${doctorId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      if (Array.isArray(result)) {
        setUnavailableDates(result.map((date) => new Date(date.date)));
      } else {
        console.error("Unexpected response structure:", result);
      }
    } catch (error) {
      console.error("Error fetching unavailable dates:", error);
    }
  };

  useEffect(() => {
    if (showReschedule) {
      fetchUnavailableDates(doctorId);
    }
  }, [showReschedule, doctorId]);
  const isDateSelectable = (date) => {
    const dayOfWeek = date.getDay();
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayName = dayNames[dayOfWeek];
    return availableDays.includes(dayName);
  };

  const isDateUnavailable = (date) => {
    const selectable = isDateSelectable(date);

    const unavailable = unavailableDates.some(
      (unavailableDate) =>
        unavailableDate.toDateString() === date.toDateString()
    );

    return !selectable || unavailable;
  };
  // const handleDateChange = (date) => {
  //   const formattedDate = date.toISOString().split("T")[0];
  //   setNewDate(formattedDate);

  //   fetchAvailableSlots(doctorId, formattedDate);
  // };
  const handleDateChange = async (date) => {
    // Convert the date to the local timezone
    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    const formattedDate = localDate.toISOString().split("T")[0];
    setNewDate(formattedDate);
    await fetchAvailableSlots(doctorId, formattedDate);
  };

  // Existing fetchAvailableSlots function
  const fetchAvailableSlots = async (doctorId, date) => {
    try {
      const formattedDate = date.split("T")[0];
      const today = new Date().toISOString().split("T")[0];
      const currentTime = new Date().toTimeString().split(" ")[0];

      const response = await axios.get(
        `${baseURL}/doctor/${doctorId}/available-slots/?date=${formattedDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.length > 0) {
        const isToday = formattedDate === today;
        const slots = isToday
          ? response.data.filter((slot) => slot.start_time > currentTime)
          : response.data;

        setAvailableSlots(slots);
      } else {
        setAvailableSlots([]);
      }
    } catch (error) {
      console.error("Error fetching available slots:", error);
      setAvailableSlots([]);
    }
  };
  const getToastMessage = (action) => {
    switch (action) {
      case "reschedule":
        return "Appointment rescheduled successfully";
      case "cancelled":
        return "Appointment cancelled successfully";
      default:
        return "Operation successful";
    }
  };
  const isDateInPast = (date) => new Date(date) < new Date();

  const handleApiCall = async (action, newDate = null, newTime = null) => {
    setLoadingAction(action);
    const formattedTime = newTime ? newTime.slice(0, 5) : null;
    const method = action === "cancelled" ? "DELETE" : "PUT"; // Use DELETE for cancel action

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/patient/appointment/rescheduleANDdelete/${appointment.id}/`,
        {
          method: method,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body:
            action === "cancelled"
              ? null
              : JSON.stringify({
                  appointment_date: newDate,
                  appointment_time: formattedTime,
                }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      toast.success(getToastMessage(action));
      setTimeout(() => {
        fetchData();
      }, 3000);
    } catch (error) {
      console.error("Error updating appointment status:", error);
      toast.error("Error updating appointment status");
    } finally {
      setLoadingAction(null);
      setShow(false);
      setShowReschedule(false);
      setShowCancel(false);
    }
  };
  useEffect(() => {
    setFirstRender(true);
  }, []);
  const relevantPrescriptions = allPrescription?.filter(
    (prescription) =>
      prescription.doctor === appointment?.doctor &&
      prescription.appointment === appointment?.id
  );
  // console.log(
  //   "relevantPrescriptions",
  //   relevantPrescriptions,
  //   "allPrescription",
  //   allPrescription,
  //   "appointment",
  //   appointment
  // );
  // Find the latest prescription
  const prescription = relevantPrescriptions?.reduce((latest, current) => {
    return new Date(latest.date) > new Date(current.date) ? latest : current;
  }, relevantPrescriptions[0] || null);
  // setPrescription(latestPrescription ? [latestPrescription] : null);
  const prescriptionHistory = allPrescription?.filter(
    (d) => d.doctor === appointment.doctor
  );

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     const data = await response.json();

  //     // Filter prescriptions to match the current appointment's doctor and patient
  //     const relevantPrescriptions = data.filter(
  //       (prescription) =>
  //         prescription.doctor === appointment.doctor &&
  //         prescription.appointment === appointment.id
  //     );

  //     setPrescription(
  //       relevantPrescriptions.length > 0 ? relevantPrescriptions : null
  //     );
  //     const filtePrescriptionHistory = data?.filter(
  //       (d) => d?.doctor === appointment?.doctor
  //     );
  //     setPrescriptionHistory(filtePrescriptionHistory);
  //   } catch (error) {
  //     console.error("Error fetching prescription:", error);
  //     toast.error("Error fetching prescription");
  //   }
  // };
  const formatAppointmentDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); 
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear(); 
    
    return `${day}/${month}/${year}`; 
};

  const printAppointmentDetails = () => {
    const printWindow = window.open("", "", "height=600,width=800");

    printWindow.document.write(
      "<html><head><title>Print Appointment Details</title>"
    );
    printWindow.document.write("<style>");
    printWindow.document.write(
      "body { font-family: Arial, sans-serif; padding: 20px; }"
    );
    printWindow.document.write(
      "h1 { text-align: center; margin-bottom: 20px; border-bottom: 2px solid #000; padding-bottom: 10px; }"
    );
    printWindow.document.write(
      "h2 { text-align: center; margin-bottom: 20px; }"
    );
    printWindow.document.write(
      "table { width: 100%; border-collapse: collapse; margin-top: 20px; }"
    );
    printWindow.document.write(
      "td, th { padding: 8px; text-align: left; border: 1px solid #ddd; }"
    );
    printWindow.document.write("th { background-color: #f4f4f4; }");
    printWindow.document.write(
      "tr:nth-child(even) { background-color: #f9f9f9; }"
    );
    printWindow.document.write("</style>");
    printWindow.document.write("</head><body>");

    printWindow.document.write("<h1>Appointment Details</h1>");
    printWindow.document.write("<h2>" + appointment.department_name + "</h2>"); // Department name centered

    printWindow.document.write("<table>");
    printWindow.document.write(
      "<tr><td><strong>Appointment No.:</strong> " + appointment.id + "</td>"
    );
    printWindow.document.write(
      "<td><strong>Appointment Date:</strong> " +
        appointment.appointment_date +
        "</td></tr>"
    );
    printWindow.document.write(
      '<tr><td colspan="2"><strong>Patient Name:</strong> ' +
        appointment.patient_name +
        "</td></tr>"
    );
    printWindow.document.write(
      "<tr><td><strong>Gender:</strong> " + appointment.gender + "</td>"
    );
    printWindow.document.write(
      "<td><strong>Age:</strong> " + appointment.age + "</td></tr>"
    );
    printWindow.document.write(
      '<tr><td colspan="2"><strong>Request Mode:</strong> ' +
        appointment.request_mode +
        "</td></tr>"
    );
    printWindow.document.write(
      '<tr><td colspan="2"><strong>Contact Details:</strong> ' +
        appointment.contact_details +
        "</td></tr>"
    );
    printWindow.document.write(
      '<tr><td colspan="2"><strong>Reporting Place:</strong> ' +
        appointment.reporting_place +
        "</td></tr>"
    );
    printWindow.document.write(
      '<tr><td colspan="2"><strong>Note:</strong> ' +
        appointment.note +
        "</td></tr>"
    );
    printWindow.document.write("</table>");

    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };

  const downloadPDF = (prescriptionItem) => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 14; // Margin from the left side
    const centerX = pageWidth / 2;
    const titleX = centerX; // Center title
    const smallFontSize = 10; // Smaller font size for details
    const lineY = 20; // Y position for the line below the title
    const tableStartY = 48; // Y position to start the table
    const headerLineWidth = 0.5; // Line width for the header lines
    const headerLineHeight = 6; // Distance from the table startY to draw the line

    // Title - Centered and Bold
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(14); // Larger size for title
    doc.text("Prescription Details", titleX, 16, { align: "center" });

    // Draw separator line below the title
    doc.setLineWidth(0.5); // Line width
    doc.line(margin, lineY, pageWidth - margin, lineY); // Draw line from left to right

    // Reset font to normal and set smaller font size
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(smallFontSize);

    // Patient and Doctor Names
    const patientIDText = `Patient Name: ${prescriptionItem.patient_name}`;
    const doctorIDText = `Doctor Name: ${prescriptionItem.doctor_first_name} ${prescriptionItem.doctor_last_name}`;
    const textWidthPatient = doc.getTextWidth(patientIDText);
    const textWidthDoctor = doc.getTextWidth(doctorIDText);

    // Patient Name on the left
    doc.text(patientIDText, margin, 24);

    // Doctor Name on the right
    doc.text(doctorIDText, pageWidth - margin - textWidthDoctor, 24);

    // Date and Description
    doc.text(`Date: ${prescriptionItem.date}`, margin, 32);
    doc.text(`Description: ${prescriptionItem.description}`, margin, 40);

    // Add Medicine Details
    const medicineData = prescriptionItem.items.map((item) => [
      item.medicine_name,
      item.dosage,
      item.frequency,
      item.duration,
    ]);

    doc.autoTable({
      startY: tableStartY, // Adjust the starting Y position after text
      head: [["Medicine Name", "Dosage", "Frequency", "Duration"]],
      body: medicineData,
      theme: "plain", // Ensure a plain theme with no color
      styles: {
        fontSize: smallFontSize, // Apply smaller font size for table
        fillColor: [255, 255, 255], // White background (not necessary but ensures no color)
        textColor: [0, 0, 0], // Black text color
      },
      headStyles: {
        fillColor: [255, 255, 255], // White background for header
        textColor: [0, 0, 0], // Black text color for headers
      },
      didDrawPage: () => {
        // Draw separator line below the title
        doc.setLineWidth(headerLineWidth); // Line width
        doc.line(margin, lineY, pageWidth - margin, lineY); // Draw line from left to right

        // Draw separator line above the table header
        const headerY = tableStartY - headerLineHeight; // Y position for the line above the header
        doc.line(margin, headerY, pageWidth - margin, headerY); // Draw line from left to right

        // Draw separator line below the table header
        const headerBottomY = tableStartY + 6; // Y position for the line below the header
        doc.line(margin, headerBottomY, pageWidth - margin, headerBottomY); // Draw line from left to right
      },
    });

    // Save PDF
    doc.save("prescription.pdf");
  };
  const downloadPrescriptionHistory = (data) => {
    const doc = new jsPDF();

    // Set title
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 14; // Margin from the left side
    const centerX = pageWidth / 2;
    const titleX = centerX; // Center title
    const smallFontSize = 10; // Smaller font size for details
    const lineY = 20; // Y position for the line below the title
    const tableStartY = 48; // Y position to start the table

    // Title - Centered and Bold
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(16); // Larger size for title
    doc.text("Prescription Details", titleX, 16, { align: "center" });

    // Draw separator line below the title
    doc.setLineWidth(0.5); // Line width
    doc.line(margin, lineY, pageWidth - margin, lineY); // Draw line from left to right

    // Reset font to normal and set smaller font size
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(smallFontSize);

    // Patient Name on the left
    const patientIDText = `Patient Name: ${data[0]?.patient_name}`;
    doc.text(patientIDText, margin, 24);

    // Doctor Information in one column on the right
    const doctorInfo = [
      `Doctor Name: ${data[0]?.doctor_first_name} ${data[0]?.doctor_last_name}`,
      `Graduation Year: ${data[0]?.doctor_graduation_year}`,
      `Medical School: ${data[0]?.doctor_medical_school}`,
      `Specialization: ${data[0]?.doctor_specialization}`,
      `Experience: ${data[0]?.doctor_years_of_experience} Year`,
      `License Number: ${data[0]?.doctor_medical_license_number}`,
    ];

    // Ensure that all doctor info is aligned
    const doctorTextYStart = 24; // Starting Y position for the doctor info
    let currentY = doctorTextYStart;
    const maxDoctorTextWidth = Math.max(
      ...doctorInfo.map((line) => doc.getTextWidth(line))
    );

    doctorInfo.forEach((line) => {
      doc.text(line, pageWidth - margin - maxDoctorTextWidth, currentY);
      currentY += 6; // Line spacing for doctor information
    });

    // Draw a separator line between the doctor/patient info and the table
    doc.setLineWidth(0.3); // Thinner line
    doc.line(margin, currentY + 5, pageWidth - margin, currentY + 5); // Draw line below doctor/patient info

    // Prepare the data for the PDF table
    const tableData = [];

    data.forEach((entry) => {
      const entryData = [
        {
          content: entry.description,
          rowSpan: entry.items.length || 1, // span over the number of items
        },
        {
          content: entry.date,
          rowSpan: entry.items.length || 1, // span over the number of items
        },
        // Create the first row of items, or empty cells if no items
        ...(entry.items.length > 0
          ? [
              entry.items[0].medicine_name,
              entry.items[0].dosage,
              entry.items[0].frequency,
              entry.items[0].duration,
            ]
          : ["", "", "", ""]),
      ];

      tableData.push(entryData);

      // Add the remaining items as new rows
      for (let i = 1; i < entry.items.length; i++) {
        const item = entry.items[i];
        tableData.push([
          item.medicine_name,
          item.dosage,
          item.frequency,
          item.duration,
        ]);
      }
    });

    // Add table to the PDF
    doc.autoTable({
      head: [
        [
          {
            content: "Description",
            styles: {
              fillColor: [220, 220, 220],
              textColor: [0, 0, 0],
              fontStyle: "bold",
            },
          },
          {
            content: "Date",
            styles: {
              fillColor: [220, 220, 220],
              textColor: [0, 0, 0],
              fontStyle: "bold",
            },
          },
          {
            content: "Medicine Name",
            styles: {
              fillColor: [220, 220, 220],
              textColor: [0, 0, 0],
              fontStyle: "bold",
            },
          },
          {
            content: "Dosage",
            styles: {
              fillColor: [220, 220, 220],
              textColor: [0, 0, 0],
              fontStyle: "bold",
            },
          },
          {
            content: "Frequency",
            styles: {
              fillColor: [220, 220, 220],
              textColor: [0, 0, 0],
              fontStyle: "bold",
            },
          },
          {
            content: "Duration",
            styles: {
              fillColor: [220, 220, 220],
              textColor: [0, 0, 0],
              fontStyle: "bold",
            },
          },
        ],
      ],
      body: tableData,
      startY: currentY + 10, // Adjust starting Y position to be below the separator line
      styles: {
        fillColor: [255, 255, 255], // Set the default background to white for rows
        textColor: [0, 0, 0], // Set text color to black
        lineWidth: 0.2, // Border line width
        lineColor: [0, 0, 0], // Border line color
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Light gray background for alternate rows
      },
    });

    // Save the PDF
    doc.save("PrescriptionHistory.pdf");
  };

  return (
    <>
      <td className="text-end p-3">
        {appointment.status === "pending" ? (
          <>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-view">View Details</Tooltip>}
            >
              <Link
                to="#"
                className="btn btn-icon btn-pills btn-soft-primary"
                onClick={() => setShow(!show)}
              >
                <FiEye />
              </Link>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-cancel">Cancel Appointment</Tooltip>
              }
            >
              <Link
                to="#"
                className="btn btn-icon btn-pills btn-soft-danger"
                onClick={() => setShowCancel(true)}
              >
                <LiaTimesCircleSolid />
              </Link>
            </OverlayTrigger>
          </>
        ) : appointment.status === "rejected" ||
          appointment.status === "completed" ? (
          <>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-view">View Details</Tooltip>}
            >
              <Link
                to="#"
                className="btn btn-icon btn-pills btn-soft-primary"
                onClick={() => setShow(!show)}
              >
                <FiEye />
              </Link>
            </OverlayTrigger>
            {appointment.status === "completed" && (
              <>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-prescription">
                      View Prescription
                    </Tooltip>
                  }
                >
                  <Link
                    to="#"
                    className="btn btn-icon btn-pills btn-soft-info"
                    onClick={() => setShowPrescription(true)}
                  >
                    <FaFilePdf />
                  </Link>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-prescription">
                      View Prescription History
                    </Tooltip>
                  }
                >
                  <Link
                    to="#"
                    className="btn btn-icon btn-pills btn-soft-secondary"
                    onClick={() => setShowPrescriptionHistory(true)}
                  >
                    <FaPrescription />
                  </Link>
                </OverlayTrigger>
              </>
            )}
          </>
        ) : appointment.status === "cancelled" ||
          appointment.status === "referred" ? (
          <>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-view">View Details</Tooltip>}
            >
              <Link
                to="#"
                className="btn btn-icon btn-pills btn-soft-primary"
                onClick={() => setShow(!show)}
              >
                <FiEye />
              </Link>
            </OverlayTrigger>
          </>
        ) : (
          <>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-view">View Details</Tooltip>}
            >
              <Link
                to="#"
                className="btn btn-icon btn-pills btn-soft-primary"
                onClick={() => setShow(!show)}
              >
                <FiEye />
              </Link>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-cancel">Cancel Appointment</Tooltip>
              }
            >
              <Link
                to="#"
                className="btn btn-icon btn-pills btn-soft-danger"
                onClick={() => setShowCancel(true)}
              >
                <LiaTimesCircleSolid />
              </Link>
            </OverlayTrigger>
            {/* Check if the appointment date is in the future */}
            {new Date(appointment.appointment_date) > new Date() && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-reschedule">
                    Reschedule Appointment
                  </Tooltip>
                }
              >
                <Link
                  to="#"
                  className="btn btn-icon btn-pills btn-soft-warning"
                  onClick={() => setShowReschedule(true)}
                >
                  <FaCalendarAlt />
                </Link>
              </OverlayTrigger>
            )}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-prescription">View Prescription</Tooltip>
              }
            >
              <Link
                to="#"
                className="btn btn-icon btn-pills btn-soft-info"
                onClick={() => setShowPrescription(true)}
              >
                <FaFilePdf />
              </Link>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-prescription">
                  View Prescription History
                </Tooltip>
              }
            >
              <Link
                to="#"
                className="btn btn-icon btn-pills btn-soft-secondary"
                onClick={() => setShowPrescriptionHistory(true)}
              >
                <FaPrescription />
              </Link>
            </OverlayTrigger>
          </>
        )}
      </td>

      {/* View Details Modal */}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Appointment Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body p-3 pt-4">
            <div className="d-flex align-items-center">
              <img
                src={client1}
                className="avatar avatar-small rounded-pill"
                alt=""
              />
              <h5 className="mb-0 ms-3">{appointment.patient_name}</h5>
            </div>
            <ul className="list-unstyled mb-0 d-md-flex justify-content-between mt-4">
              <li>
                <ul className="list-unstyled mb-0">
                  <li className="d-flex">
                    <h6>Age:</h6>
                    <p className="text-muted ms-2">
                      {appointment.age} year old
                    </p>
                  </li>
                  <li className="d-flex">
                    <h6>Gender:</h6>
                    <p className="text-muted ms-2">{appointment.gender}</p>
                  </li>
                  <li className="d-flex">
                    <h6 className="mb-0">Reason:</h6>
                    <p className="text-muted ms-2 mb-0">{appointment.reason}</p>
                  </li>
                  {appointment.comment && (
                    <li className="d-flex flex-column flex-sm-row">
                      <h6 className="mb-1 mb-sm-0">
                        Reason for cancel/Reschedule:
                      </h6>
                      <p className="text-muted ms-2 mb-0">
                        {appointment.comment}
                      </p>
                    </li>
                  )}
                </ul>
              </li>
              <li>
                <ul className="list-unstyled mb-0">
                  <li className="d-flex">
                    <h6>Date:</h6>
                    <p className="text-muted ms-2">
                      {/* {appointment.appointment_date} */}
                      {formatAppointmentDate(appointment.appointment_date)}
                    </p>
                  </li>
                  <li className="d-flex">
                    <h6>Time:</h6>
                    <p className="text-muted ms-2">
                      {appointment.appointment_time}
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
            {appointment.status === "confirmed" && (
              <button
                className="btn btn-primary mt-3"
                onClick={printAppointmentDetails}
              >
                Print Details
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>

      {/* Reschedule Modal */}
      <Modal
        show={showReschedule}
        onHide={() => setShowReschedule(false)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Reschedule Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body" style={{ padding: "1rem" }}>
          {appointment.appointment_type == "clinic" && (
        <div style={{ marginBottom: "1rem" }}>
          <label
            htmlFor="clinicInput"
            style={{
              display: "block",
              marginBottom: "0.5rem",
              fontWeight: "bold",
            }}
          >
            Clinic
          </label>
          <input
            type="text"
            id="clinicInput"
            value={appointment.Clinicname_address} // Display the clinic address
            readOnly // Make it read-only if you don't want it to be editable
            className="form-control"
          />
        </div>
      )}
               <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}>

               <div style={{ flex: 1, marginRight: "0.5rem" }}>

              <label
                htmlFor="rescheduleDate"
                style={{
                  display: "block",
                  marginBottom: "0.5rem",
                  fontWeight: "bold",
                }}
              >
                New Date
              </label>
            
              <DatePicker
                selected={newDate ? new Date(newDate) : null}
                onChange={handleDateChange}
                filterDate={(date) => !isDateUnavailable(date)}
                minDate={new Date()}
                maxDate={getMaxDate()}
                dateFormat="dd-MM-yyyy"
                required
                className="form-select form-control"
                renderDayContents={(day, date) => {
                  const today = new Date();
                  const maxSelectableDate = new Date(today);
                  maxSelectableDate.setDate(today.getDate() + 15);
                  const isPastDate = date < today;
                  const isExceedingDate = date > maxSelectableDate;
                  const isWithinRange =
                    date >= today && date <= maxSelectableDate;
                  const isSelectable = isDateSelectable(date);
                  const isSelected =
                    date.toDateString() ===
                    (newDate ? new Date(newDate).toDateString() : null);

                  const styles = {
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    cursor:
                      isPastDate || isExceedingDate
                        ? "not-allowed"
                        : isWithinRange && isSelectable
                        ? "pointer"
                        : "default",
                    backgroundColor: isSelected
                      ? "skyblue"
                      : isWithinRange && isSelectable
                      ? "blue"
                      : "transparent",
                    color:
                      isSelected || (isWithinRange && isSelectable)
                        ? "white"
                        : "gray",
                  };

                  return <span style={styles}>{day}</span>;
                }}
              />
            </div>

            <div style={{ marginBottom: "1rem" }}>
              <label
                htmlFor="rescheduleTime"
                style={{
                  display: "block",
                  marginBottom: "0.5rem",
                  fontWeight: "bold",
                }}
              >
                New Time
              </label>
              <select
                id="rescheduleTime"
                // style={{ width: '100%', padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid #ced4da' }}
                value={newTime}
                onChange={(e) => setNewTime(e.target.value)}
                required
                className="form-select form-control"
              >
                <option value="">Select a time</option>
                {availableSlots.length > 0 ? (
                  availableSlots.map((slot, index) => (
                    <option key={index} value={slot.start_time}>
                      {convertTo12HourFormat(slot.start_time)}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    No available slots
                  </option>
                )}
              </select>
            </div>
            </div>

            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <button
                style={{
                  padding: "0.5rem 1rem",
                  fontSize: "1rem",
                  color: "#fff",
                  backgroundColor: "#007bff",
                  border: "none",
                  borderRadius: "0.25rem",
                  cursor: "pointer",
                }}
                onClick={() => handleApiCall("reschedule", newDate, newTime)}
                disabled={loadingAction === "reschedule"}
              >
                {loadingAction === "reschedule" ? "Saving..." : "Reschedule"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
     

      {/* Cancel Modal */}
      <Modal
        show={showCancel}
        onHide={() => setShowCancel(false)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Cancel Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-muted">
            Are you sure you want to cancel this appointment?
          </p>
          <button
            className="btn btn-danger"
            onClick={() => handleApiCall("cancelled")}
            disabled={loadingAction === "cancelled"}
          >
            {loadingAction === "cancelled"
              ? "Cancelling..."
              : "Cancel Appointment"}
          </button>
        </Modal.Body>
      </Modal>

      <Modal
        show={showPrescription}
        onHide={() => setShowPrescription(false)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Prescription Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {prescription && Object.values(prescription)?.length > 0 ? (
           
            <div className="modal-body p-3 ">
              <div className="mb-3">
                <TextField
                  label="Doctor Name"
                  variant="outlined"
                  fullWidth
                  value={`${prescription?.doctor_first_name} ${prescription?.doctor_last_name}`}
                  InputLabelProps={{
                    shrink: !!(
                      prescription?.doctor_first_name ||
                      prescription?.doctor_last_name
                    ),
                  }}
                  InputProps={{
                    style: { height: "40px", padding: "0 10px" },
                  }}
                  readOnly // Make it read-only since it's a display
                />
              </div>

              <div className="mb-3">
                <TextField
                  label="Date"
                  variant="outlined"
                  fullWidth
                  value={prescription?.date}
                  InputLabelProps={{
                    shrink: !!prescription?.date,
                  }}
                  InputProps={{
                    style: { height: "40px", padding: "0 10px" },
                  }}
                  readOnly
                />
              </div>

              <div className="mb-3">
                <TextField
                  label="Description"
                  variant="outlined"
                  fullWidth
                  value={prescription.description}
                  InputLabelProps={{
                    shrink: !!prescription.description,
                  }}
                  InputProps={{
                    style: { height: "40px", padding: "0 10px" },
                  }}
                  readOnly
                />
              </div>

              <div className="mb-3">
                <h6>Medicines</h6>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Medicine</th>
                      <th>Dosage</th>
                      <th>Frequency</th>
                      <th>Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    {prescription?.items.map((item) => (
                      <tr key={item.id}>
                        <td>{item.medicine_name}</td>
                        <td>{item.dosage}</td>
                        <td>{item.frequency}</td>
                        <td>{item.duration}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <button
                className="btn btn-primary"
                onClick={() => downloadPDF(prescription)}
              >
                Download PDF
              </button>
            </div>
          ) : (
            <p>No prescription details available for this appointment.</p>
          )}
        </Modal.Body>
      </Modal>
      {showPrescriptionHistory && (
        <PrescriptionHistory
          prescription={prescriptionHistory}
          showPrescriptionHistory={showPrescriptionHistory}
          setShowPrescriptionHistory={setShowPrescriptionHistory}
          downloadPDF={downloadPrescriptionHistory}
        />
      )}
    </>
  );
}
