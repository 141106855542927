import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import image1 from "../../assets/images/doctors/01.jpg";
import { useDispatch, useSelector } from "react-redux";
import { doctorDetails } from "../../store/doctor/actions";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import AdminFooter from "../../components/dashboard/adminFooter";
import { ShowError, ShowSucess } from "../../_helper/helper";
import { loadingPanel } from "../../components/CommonComponents/CommonFunction";
import { ToastContainer, toast } from "react-toastify";
import Header from "../../components/CommonComponents/Header";
const baseURL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("accessToken");

export default function DoctorProfileSettimg() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { doctorDetailsResults } = useSelector((state) => state.DoctorReducer);
  const { image } = doctorDetailsResults;
  const location = useLocation();
  const showBackButton = location.key !== "default";
  const doctorid = localStorage.getItem("doctorId");
  const [userData, setUserData] = useState(null);
  const getUserData = () => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      return JSON.parse(userData);
    }
    return null;
  };
  const handleUpdateClick = async (file) => {
    try {
      const formData = new FormData();
      formData.append("image", file);
      const result = await axios.put(
        `${baseURL}/doctor/update-image/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resp = result.data.image;
      if (resp) {
        const doctorid = localStorage.getItem("doctorId");
        dispatch(doctorDetails(doctorid));
        ShowSucess("Profile Updated Successfully");
      } else {
        ShowError("Something Went Wrong!.");
      }
    } catch (err) {
      ShowError(err);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleUpdateClick(file);
    }
  };
  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const result = await axios.delete(
        `${baseURL}/doctors/${Number(doctorid)}/remove-image/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("deleteresp", result);
      const resp = result.data.image;
      if (resp) {
        setLoading(false);
        dispatch(doctorDetails(doctorid));
        ShowSucess("Profile Removed Successfully!");
      } else {
        ShowError("Something Went Wrong!.");
        setLoading(false);
      }
    } catch (err) {
      ShowError(err);
      setLoading(false);
    }
  };

  const updateDetails = async () => {
    const url = `${process.env.REACT_APP_API_URL}/user/update/`;
  
    const data = {
      first_name: document.getElementById("first_name").value,
      last_name: document.getElementById("last_name").value,
      email: document.getElementById("email").value,
      phone_number: document.getElementById("phone_number").value,
    };
  
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
  
      if (response.ok) {
        const updatedUserData = { ...getUserData(), user: data };
        localStorage.setItem("userData", JSON.stringify(updatedUserData));
        setUserData(updatedUserData);
        toast.success("Details updated successfully");
      } else {
        // Extract the error message from the response
        const errorData = await response.json();
  
        // Handling multiple error fields if they exist
        const errorMessages = Object.values(errorData)
          .flat() // Flatten the arrays of messages
          .join(", "); // Join messages in case of multiple errors
  
        toast.error(errorMessages || "Failed to update details.");
        console.error("Failed to update details:", errorMessages);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while updating details.");
    }
  };
  
  // const updateDetails = async () => {
  //   const url = `${process.env.REACT_APP_API_URL}/user/update/`;

  //   const data = {
  //     first_name: document.getElementById("first_name").value,
  //     last_name: document.getElementById("last_name").value,
  //     email: document.getElementById("email").value,
  //     phone_number: document.getElementById("phone_number").value,
  //   //   age: document.getElementById("age").value,
  //   //   blood_type: document.getElementById("blood_type").value,
  //   //   gender: "M",
  //   };

  //   try {
  //     const response = await fetch(url, {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify(data),
  //     });

  //     if (response.ok) {
  //       // Update local storage with the new user details
  //       const updatedUserData = { ...getUserData(), user: data };
  //       localStorage.setItem("userData", JSON.stringify(updatedUserData));

  //       // Update state with the new user details
  //       setUserData(updatedUserData);

  //       toast.success("Details updated successfully");
  //       console.log("Details updated successfully");
  //     } else {
  //       toast.error("Failed to update details.");
  //       console.error("Failed to update details");
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) errors.push('Password must be at least 8 characters long.');
    if (!/[A-Z]/.test(password)) errors.push('Password must contain at least one uppercase letter.');
    if (!/[0-9]/.test(password)) errors.push('Password must contain at least one number.');
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) errors.push('Password must contain at least one special character.');
    return errors;
  };

  const handlePasswordChange = (e) => {
    const { id, value } = e.target;
    if (id === 'current_password') setCurrentPassword(value);
    if (id === 'new_password') {
      setNewPassword(value);
      setPasswordErrors(validatePassword(value));
    }
    if (id === 'confirm_new_password') setConfirmNewPassword(value);
  };

  const changePassword = async () => {
    if (passwordErrors.length > 0) {
      setError('Please fix the errors in the password fields.');
      return;
    }

    if (!currentPassword || !newPassword || !confirmNewPassword) {
      setError('All fields are required.');
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setError('New passwords do not match.');
      return;
    }

    try {
      const url = `${process.env.REACT_APP_API_URL}/users/change-password/`;

      const data = {
        current_password: currentPassword,
        new_password: newPassword,
        confirm_new_password: confirmNewPassword,
      };

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Ensure `token` is defined in your component
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
        setPasswordErrors([]);
        toast.success('Password updated successfully!');
        // setSuccess('Password updated successfully!');
        setError(''); // Clear any previous error messages
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to update password. Please try again.');
        setSuccess('');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
      setSuccess('');
      console.error('Error:', error);
    }
  };
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',

  });

  useEffect(() => {
    // Retrieve and parse user data from localStorage
    const userData = JSON.parse(localStorage.getItem('userData'));

    if (userData) {
      // Update state with retrieved data
      setFormData({
        first_name: userData?.user.first_name || '',
        last_name: userData?.user.last_name || '',
        email: userData?.user.email || '',
        phone_number: userData?.user.phone_number || '',
     
      });
    }
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };
//   const [error, setError] = useState('');
//   const changePassword = async () => {
//     const currentPassword = document.getElementById("current_password").value;
//     const newPassword = document.getElementById("new_password").value;
//     const confirmNewPassword = document.getElementById("confirm_new_password").value;
  
//     if (!currentPassword || !newPassword || !confirmNewPassword) {
//       setError('All fields are required.');
//       return;
//     }
  
//     if (newPassword !== confirmNewPassword) {
//       setError('New passwords do not match.');
//       return;
//     }
  
//     try {
//       const url = `${process.env.REACT_APP_API_URL}/users/change-password/`;
  
//       const data = {
//         current_password: currentPassword,
//         new_password: newPassword,
//         confirm_new_password: confirmNewPassword,
//       };
  
//       const response = await fetch(url, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`, // Ensure `token` is defined in your component
//         },
//         body: JSON.stringify(data),
//       });
  
//       if (response.ok) {
//         // Clear password fields on successful update
//         document.getElementById("current_password").value = "";
//         document.getElementById("new_password").value = "";
//         document.getElementById("confirm_new_password").value = "";
  
//         ShowSucess('Password updated successfully!');
//         setError(''); // Clear any previous error messages
//       } else {
//         const errorData = await response.json();
//         setError(errorData.message || 'Failed to update password. Please try again.');
//       }
//     } catch (error) {
//       setError('An error occurred. Please try again.');
//       console.error("Error:", error);
//     }
//   };
  return (
    <>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container"
      />
      <section className="bg-dashboard">
        <div className="container-fluid">
          <div className="row justify-content-center">
            {loading && loadingPanel()}
            <Sidebar colClass="col-xl-4 col-lg-4 col-md-5 col-12" />

            <div className="col-xl-8 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <Header
                title="Profile Settings" // Replace with the title you want
                onBackClick={() => navigate(-1)}
                showBackButton={showBackButton}
              />
              {/* <h5 className="mb-0 pb-2">Schedule Timing</h5> */}
              <div className="rounded shadow mt-4">
                <div className="p-4 border-bottom">
                  <h5 className="mb-0">Personal Information :</h5>
                </div>

                <div className="p-4 border-bottom">
                  <div className="row align-items-center">
                    <div className="col-lg-2 col-md-4">
                      <img
                        src={image || image1}
                        className="avatar avatar-md-md rounded-pill shadow mx-auto d-block"
                        alt=""
                      />
                    </div>

                    <div className="col-lg-5 col-md-8 text-center text-md-start mt-4 mt-sm-0">
                      <h5 className="">Upload your picture</h5>
                      <p className="text-muted mb-0">
                        For best results, use an image at least 256px by 256px
                        in either .jpg or .png format
                      </p>
                    </div>

                    <div className="col-lg-5 col-md-12 text-lg-end text-center mt-4 mt-lg-0">
                      <label
                        htmlFor="profile_pic" // Use `htmlFor` in JSX instead of `for`
                        className="btn btn-primary"
                        style={{
                          cursor: "pointer",
                          height: "41px",
                          marginTop: "11px",
                        }}
                      >
                        Upload
                      </label>
                      <input
                        type="file"
                        id="profile_pic"
                        name="profile_pic"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        accept=".jpg, .jpeg, .png"
                      />

                      <Link
                        to="#"
                        className="btn btn-soft-primary ms-2"
                        onClick={(e) => handleDelete(e)}
                      >
                        Remove
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="p-4">
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">First Name</label>
                          <input
                                name="first_name"
                                id="first_name"
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                                value={formData.first_name}
                                onChange={handleChange}
                              />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Last Name</label>
                          <input
                                name="last_name"
                                id="last_name"
                                type="text"
                                className="form-control"
                                placeholder="Last Name"
                                value={formData.last_name}
                                onChange={handleChange}
                              />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Your Email</label>
                          <input
                                name="email"
                                id="email"
                                type="email"
                                className="form-control"
                                placeholder="Your email"
                                value={formData.email}
                                onChange={handleChange}
                              />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Phone no.</label>
                          <input
                                name="phone_number"
                                id="phone_number"
                                type="number"
                                className="form-control"
                                placeholder="Phone no."
                                value={formData.phone_number}
                                onChange={handleChange}
                              />
                        </div>
                      </div>

                      {/* <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label">Your Bio Here</label>
                          <textarea
                            name="comments"
                            id="comments"
                            rows="4"
                            className="form-control"
                            placeholder="Bio :"
                          ></textarea>
                        </div>
                      </div> */}
                    </div>

                    <div className="row">
                          <div className="col-sm-12">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={updateDetails}
                            >
                              Update Details
                            </button>
                          </div>
                        </div>
                  </form>
                </div>
              </div>

              <div className="rounded shadow mt-4">
      <div className="p-4 border-bottom">
        <h5 className="mb-0">Change Password :</h5>
      </div>

      <div className="p-4">
        <form className="mt-4">
          <div className="row">
            <div className="col-lg-12">
              <div className="mb-3">
                <label className="form-label">Current Password</label>
                <input
                  name="current_password"
                  id="current_password"
                  type="password"
                  className="form-control"
                  placeholder="Current password"
                  value={currentPassword}
                  onChange={handlePasswordChange}
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="mb-3">
                <label className="form-label">New Password</label>
                <input
                  name="new_password"
                  id="new_password"
                  type="password"
                  className="form-control"
                  placeholder="New password"
                  value={newPassword}
                  onChange={handlePasswordChange}
                />
                <ul className="mt-2">
                  {passwordErrors.map((error, index) => (
                    <li key={index} className="text-danger">{error}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="mb-3">
                <label className="form-label">Confirm New Password</label>
                <input
                  name="confirm_new_password"
                  id="confirm_new_password"
                  type="password"
                  className="form-control"
                  placeholder="Re-type New password"
                  value={confirmNewPassword}
                  onChange={handlePasswordChange}
                />
              </div>
            </div>

            {error && (
              <div className="col-lg-12 mb-3">
                <div className="alert alert-danger">
                  {error}
                </div>
              </div>
            )}


            <div className="col-lg-12 mt-2 mb-0">
              <button
                type="button"
                className="btn btn-primary"
                onClick={changePassword}
              >
                Save Password
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

              {/* <div className="rounded shadow mt-4">
                <div className="p-4 border-bottom">
                  <h5 className="mb-0">Account Notifications :</h5>
                </div>

                <div className="p-4">
                  <div className="d-flex justify-content-between pb-4">
                    <h6 className="mb-0 fw-normal">When someone mentions me</h6>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value=""
                        id="customSwitch1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customSwitch1"
                      ></label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-4 border-top">
                    <h6 className="mb-0 fw-normal">When someone follows me</h6>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitch2"
                        defaultChecked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customSwitch2"
                      ></label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-4 border-top">
                    <h6 className="mb-0 fw-normal">When shares my activity</h6>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitch3"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customSwitch3"
                      ></label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-4 border-top">
                    <h6 className="mb-0 fw-normal">When someone messages me</h6>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitch4"
                        defaultChecked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customSwitch4"
                      ></label>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="rounded shadow mt-4">
                <div className="p-4 border-bottom">
                  <h5 className="mb-0">Marketing Notifications :</h5>
                </div>

                <div className="p-4">
                  <div className="d-flex justify-content-between pb-4">
                    <h6 className="mb-0 fw-normal">
                      There is a sale or promotion
                    </h6>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitch5"
                        defaultChecked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customSwitch5"
                      ></label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-4 border-top">
                    <h6 className="mb-0 fw-normal">Company news</h6>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitch6"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customSwitch6"
                      ></label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-4 border-top">
                    <h6 className="mb-0 fw-normal">Weekly jobs</h6>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitch7"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customSwitch7"
                      ></label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-4 border-top">
                    <h6 className="mb-0 fw-normal">Unsubscribe News</h6>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitch8"
                        defaultChecked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customSwitch8"
                      ></label>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="rounded shadow mt-4">
                <div className="p-4 border-bottom">
                  <h5 className="mb-0 text-danger">Delete Account :</h5>
                </div>

                <div className="p-4">
                  <h6 className="mb-0 fw-normal">
                    Do you want to delete the account? Please press below
                    "Delete" button
                  </h6>
                  <div className="mt-4">
                    <button className="btn btn-danger">Delete Account</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
      <AdminFooter />
    </>
  );
}
