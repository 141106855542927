import React, { useState, useEffect } from "react";
import {
  RiContrastDropLine,
  RiTestTubeLine,
  RiHeart3Fill,
  RiDashboard3Line,
} from "../../assets/icons/vander";

export default function Charts() {
  const id = localStorage.getItem("userId");
  const [data, setData] = useState({
    latestMetrics: {
      weight: 0,
      hemoglobin: 0,
      heartbeat: 0,
      sugarLevels: 0,
    },
    percentageDifference: {
      weight: 0,
      hemoglobin: 0,
      heartbeat: 0,
      sugarLevels: 0,
    },
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("accessToken");
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/health-metrics/list/?patient_id=${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log(result); // Log the result to see the structure

      // Get the most recent metrics data
      const latestRecord = result[0];
      if (latestRecord) {
        // Extract the latest metrics and percentage differences
        const latestMetrics = latestRecord.metrics || {};
        const percentageDifference = latestRecord.percentage_difference || {};

        // Convert metrics_with_lookup to a more convenient object if needed
        const metricsWithLookup = latestRecord.metrics_with_lookup || [];
        const metrics = metricsWithLookup.reduce((acc, metric) => {
          acc[metric.key] = {
            value: metric.value,
            unit: metric.unit,
          };
          return acc;
        }, {});

        setData({
          latestMetrics: metrics,
          percentageDifference: percentageDifference,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id, token]);
  return (
    <>
      <div className="row mt-4">
        <div className="col-12 col-lg-3">
          <div className="card border-0 rounded shadow p-4">
            <div className="d-flex justify-content-between mb-3">
              <h6 className="align-items-center mb-0">
                Weight Difference
                <span className="badge rounded-pill bg-soft-primary ms-1">
                  {data.percentageDifference.wt
                    ? `${data.percentageDifference.wt.toFixed(2)}%`
                    : "N/A"}
                </span>
              </h6>
              <RiContrastDropLine className="text-primary h5" />
            </div>
            <div className="font-weight-bold mb-2">
              <b>
                {data.latestMetrics.wt
                  ? `${data.latestMetrics.wt.value} ${data.latestMetrics.wt.unit}`
                  : "N/A"}
              </b>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-3">
          <div className="card border-0 rounded shadow p-4">
            <div className="d-flex justify-content-between mb-3">
              <h6 className="align-items-center mb-0">
                Hemoglobin Difference
                <span className="badge rounded-pill bg-soft-success ms-1">
                  {data.percentageDifference.hb
                    ? `${data.percentageDifference.hb.toFixed(2)}%`
                    : "N/A"}
                </span>
              </h6>
              <RiTestTubeLine className="text-success h5" />
            </div>
            <div className="font-weight-bold mb-2">
              <b>
                {data.latestMetrics.hb
                  ? `${data.latestMetrics.hb.value} ${data.latestMetrics.hb.unit}`
                  : "N/A"}
              </b>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-3">
          <div className="card border-0 rounded shadow p-4">
            <div className="d-flex justify-content-between mb-3">
              <h6 className="align-items-center mb-0">
                Heartbeat Difference
                <span className="badge rounded-pill bg-soft-danger ms-1">
                  {data.percentageDifference.hr
                    ? `${data.percentageDifference.hr.toFixed(2)}%`
                    : "N/A"}
                </span>
              </h6>
              <RiHeart3Fill className="text-danger h5" />
            </div>
            <div className="font-weight-bold mb-2">
              <b>
                {data.latestMetrics.hr
                  ? `${data.latestMetrics.hr.value} ${data.latestMetrics.hr.unit}`
                  : "N/A"}
              </b>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-3">
          <div className="card border-0 rounded shadow p-4">
            <div className="d-flex justify-content-between mb-3">
              <h6 className="align-items-center mb-0">
                Sugar Levels Difference
                <span className="badge rounded-pill bg-soft-warning ms-1">
                  {data.percentageDifference.sg
                    ? `${data.percentageDifference.sg.toFixed(2)}%`
                    : "N/A"}
                </span>
              </h6>
              <RiDashboard3Line className="text-warning h5" />
            </div>
            <div className="font-weight-bold mb-2">
              <b>
                {data.latestMetrics.sg
                  ? `${data.latestMetrics.sg.value} ${data.latestMetrics.sg.unit}`
                  : "N/A"}
              </b>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
