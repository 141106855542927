import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import bg1 from "../../assets/images/bg/bg-profile.jpg";
import client1 from "../../assets/images/client/09.jpg";
import logoDark from "../../assets/images/logo-dark.png";
import {
  RiEyeFill,
  RiPsychotherapyFill,
  RiStethoscopeFill,
  RiCapsuleFill,
  RiMicroscopeFill,
  RiPulseFill,
  RiEmpathizeFill,
  RiMindMap,
  RiMentalHealthLine,
  RiAppleLine,
  RiInfraredThermometerLine,
  RiCapsuleLine,
  GrUserPolice,
  RiMicroscopeLine,
  TbAmbulance,
  RiShieldCrossLine,
  RiBriefcase4Line,
  FiAirplay,
  MdOutlineFlip,
  PiTelegramLogoBold,
  RiMailStarLine,
  RiHourglassLine,
  RiHeartLine,
  RiHeartPulseLine,
  RiStethoscopeLine,
  RiVirusLine,
  RiDossierLine,
  RiEye2Line,
  FiUser,
  FiMail,
  VscBook,
  MdFormatItalic,
  GiMedicalDrip,
  FiMapPin,
  FiBriefcase,
  LuFileCheck,
  MdOutlineStorefront,
  LuAward,
  LiaFileInvoiceDollarSolid,
  FiThumbsUp,
  FiPhone,
} from "../../assets/icons/vander";
import {
  FaCheck,
  FaTimes,
  FaCalendarAlt,
  FaClock,
  FaCheckCircle,
  FaUserPlus,
  FaBan,
} from "react-icons/fa";

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import {
  adminFeature,
  appointment,
  patientProfile,
  paymentTwo,
} from "../../data/data";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { LuClipboardList, FiArrowRight } from "../../assets/icons/vander";

import Modal from "react-bootstrap/Modal";
import ScrollTop from "../../components/scrollTop";
import { useParams } from "react-router-dom/dist";
import { useAuth0 } from "@auth0/auth0-react";
import { ToastContainer, toast } from "react-toastify";
import SkeletonLoader from "../dashboard/SkeltonLoader";
import { Badge } from "react-bootstrap";
import Crud from "./Crud";
import { ShowError, ShowSucess } from "../../_helper/helper";
import { fetchPatientProfile, getUserData } from "../../components/CommonComponents/CommonFunction";

export default function PatientProfile() {
  const iconMap = {
    Cardiology: RiHeartPulseLine,
    Psychiatry: RiPsychotherapyFill,
    "General Medicine": RiStethoscopeFill,
    Pharmacy: RiCapsuleFill,
    Microbiology: RiMicroscopeFill,
    // Add other department-icon mappings here
  };
  const token = localStorage.getItem("accessToken");
  let [activeIndex, setActiveIndex] = useState(1);
  let [show, setShow] = useState(false);
  const { user } = useAuth0();
  const [appointmentData, setAppointmentData] = useState([]);
  const [allPrescription, setAllPrescription] = useState(null);
  const [userData, setUserData] = useState(null);
  console.log("useData",userData)
  const { id } = useParams(); // Get ID from URL parameters
  const formatTime = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes, seconds);
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      timeZone: "Asia/Kolkata", // "Asia/Kolkata" corresponds to IST
    });
  };

  console.log(id);

  const [loading, setLoading] = useState(true);
  const [filterStatus, setFilterStatus] = useState("confirmed");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [error, setError] = useState("");
  const [selectedDate, setSelectedDate] = useState(null); // New state for selected date
  const [searchQuery, setSearchQuery] = useState("");
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    // Retrieve the image URL from local storage
    const storedImageUrl = localStorage.getItem("profileImage");
    setImageUrl(storedImageUrl); // Set the image URL in state
  }, []);
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Filter the appointment data based on the search query
    const filtered = appointmentData.filter((item) => {
      const fullName =
        `${item.doctor_first_name} ${item.doctor_last_name}`.toLowerCase();
      return (
        fullName.includes(query.toLowerCase()) ||
        item.reason.toLowerCase().includes(query.toLowerCase())
      );
    });

    setFilteredData(filtered);
  };

  const [success, setSuccess] = useState("");
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    age: "",
    blood_type: "",
    image:""
  });
  // const [formData, setFormData] = useState({
  //   first_name: '',
  //   last_name: '',
  //   email: '',
  //   phone_number: '',
  //   age: '',
  //   blood_type: '',
  //   gender: 'M',
  // });
  const [patientImage, setImage] = useState(null);  // State for the image
  useEffect(() => {
    // Retrieve and parse user data from localStorage
    const userData = JSON.parse(localStorage.getItem("userData"));

    if (userData) {
      // Update state with retrieved data
      setFormData({
        first_name: userData?.user.first_name || "",
        last_name: userData?.user.last_name || "",
        email: userData?.user.email || "",
        phone_number: userData?.user.phone_number || "",
        age: userData?.user.age || "",
        blood_type: userData?.user.blood_type || "",
        image:userData?.user.patient_image|| client1,
      });
    }
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8)
      errors.push("Password must be at least 8 characters long.");
    if (!/[A-Z]/.test(password))
      errors.push("Password must contain at least one uppercase letter.");
    if (!/[0-9]/.test(password))
      errors.push("Password must contain at least one number.");
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password))
      errors.push("Password must contain at least one special character.");
    return errors;
  };

  const handlePasswordChange = (e) => {
    const { id, value } = e.target;
    if (id === "current_password") setCurrentPassword(value);
    if (id === "new_password") {
      setNewPassword(value);
      setPasswordErrors(validatePassword(value));
    }
    if (id === "confirm_new_password") setConfirmNewPassword(value);
  };
  const handlePreviousDay = () => {
    if (selectedDate) {
      const previousDate = new Date(selectedDate);
      previousDate.setDate(previousDate.getDate() - 1);
      setSelectedDate(previousDate);
    }
  };

  const handleToday = () => {
    setSelectedDate(new Date()); // Set to today's date
  };

  const handleNextDay = () => {
    if (selectedDate) {
      const nextDate = new Date(selectedDate);
      nextDate.setDate(nextDate.getDate() + 1);
      setSelectedDate(nextDate);
    }
  };

  const changePassword = async () => {
    if (passwordErrors.length > 0) {
      setError("Please fix the errors in the password fields.");
      return;
    }

    if (!currentPassword || !newPassword || !confirmNewPassword) {
      setError("All fields are required.");
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setError("New passwords do not match.");
      return;
    }

    try {
      const url = `${process.env.REACT_APP_API_URL}/users/change-password/`;

      const data = {
        current_password: currentPassword,
        new_password: newPassword,
        confirm_new_password: confirmNewPassword,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Ensure `token` is defined in your component
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        setPasswordErrors([]);
        toast.success("Password updated successfully!");
        // setSuccess('Password updated successfully!');
        setError(""); // Clear any previous error messages
      } else {
        const errorData = await response.json();
        setError(
          errorData.message || "Failed to update password. Please try again."
        );
        setSuccess("");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
      setSuccess("");
      console.error("Error:", error);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/patient/appointment/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Add token here
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json(); // Parse the error response
        ShowError(errorData.detail || "Network response was not ok");
      }

      const data = await response.json();
      
      setAppointmentData(data);
      setFilteredData(data);
    } catch (error) {
      console.log("datacheck", error);
      // ShowError(error.message)
    } finally {
      setLoading(false); // Hide loader
    }
  };
  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id, token, selectedDate]);
  console.log(
    "filteredData",
    filteredData.filter((d) => d?.status === "confirmed")
  );

  const fetchPrescription = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/patient/prescriptionlist/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json(); // Parse the error response
        ShowError(errorData.detail || "Network response was not ok");
      }

      const data = await response.json();
      setAllPrescription(data);
    } catch (error) {
      console.error("Error fetching prescription:", error);
      // ShowError(error.detail || "Error fetching prescription:");
    }
  };
  useEffect(() => {
    let dataToFilter = [...appointmentData];

    if (filterStatus) {
      dataToFilter = dataToFilter.filter(
        (item) => item.status.toLowerCase() === filterStatus.toLowerCase()
      );
    }
    if (selectedDate) {
      const formattedDate = selectedDate.toLocaleDateString("en-CA");
      dataToFilter = dataToFilter.filter(
        (item) => item.appointment_date === formattedDate
      );
    }

    // Sort by appointment_date (latest first) and then by appointment_time
    dataToFilter.sort((a, b) => {
      const dateA = new Date(`${a.appointment_date}T${a.appointment_time}`);
      const dateB = new Date(`${b.appointment_date}T${b.appointment_time}`);
      return dateB - dateA; // Change to dateB - dateA for descending order
    });

    setFilteredData(dataToFilter);
  }, [appointmentData, filterStatus, selectedDate]);

  // useEffect(() => {
  //   let dataToFilter = [...appointmentData];

  //   if (filterStatus) {
  //     dataToFilter = dataToFilter.filter(
  //       (item) => item.status.toLowerCase() === filterStatus.toLowerCase()
  //     );
  //   }
  //   if (selectedDate) {
  //     // Get the local date string in YYYY-MM-DD format
  //     const formattedDate = selectedDate.toLocaleDateString("en-CA");

  //     dataToFilter = dataToFilter.filter(
  //       (item) => item.appointment_date === formattedDate
  //     );
  //   }

  //   dataToFilter.sort((a, b) => {
  //     const dateA = new Date(`${a.appointment_date}T${a.appointment_time}`);
  //     const dateB = new Date(`${b.appointment_date}T${b.appointment_time}`);
  //     return dateA - dateB;
  //   });
  //   setFilteredData(dataToFilter);
  // }, [appointmentData, filterStatus, selectedDate]);

  const handleFilterChange = (status) => {
    setFilterStatus(status);
  };

  const icons = {
    gender: FiUser,
    age: FiUser, // Use appropriate icon
    phone_no: VscBook,
    blood_group: GiMedicalDrip,
  };

  // const getUserData = () => {
  //   const userData = localStorage.getItem("userData");
  //   if (userData) {
  //     return JSON.parse(userData);
  //   }
  //   return null;
  // };
  useEffect(() => {
    setUserData(getUserData());
    fetchPrescription();
  }, []);
  // Retrieve user data when the component mounts
  const handleDelete = async () => {
    // Show confirmation dialog to the user
    const confirmed = window.confirm(
      "Are you sure you want to delete your account? This action cannot be undone."
    );

    // Proceed with deletion if the user confirms
    if (confirmed) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/user/account-delete`,
          {
            method: "DELETE", // Assuming DELETE method for account deletion
            headers: {
              "Content-Type": "application/json",
              // Add authorization header if required
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        // Handle successful account deletion here
        alert("Account deleted successfully");
        // Optionally redirect or perform other actions
        window.location.href = "/login"; // Example: Redirect to login page
      } catch (error) {
        console.error("Error deleting account:", error);
        alert("Error deleting account. Please try again.");
      }
    } else {
      // User canceled the deletion
      alert("Account deletion canceled.");
    }
  };

  const profileData = userData
    ? [
        {
          icon: icons.gender,
          title: "Gender",
          name: userData?.user?.gender,
        },
        {
          icon: icons.age,
          title: "Age",
          name: userData?.user?.age,
        },
        {
          icon: icons.phone_no,
          title: "Phone No.",
          name: userData?.user?.phone_number,
        },
        {
          icon: icons.blood_group,
          title: "Blood Group",
          name: userData?.user?.blood_type,
        },
      ]
    : [];
   
console.log("patientImage",patientImage)
    const handleImageChange = (e) => {
      const file = e.target.files[0];
      setImage(file);
    };
  
    const updateDetails = async () => {
      const url = `${process.env.REACT_APP_API_URL}/user/update/`;
  
      const data = new FormData();
      data.append('first_name', document.getElementById("first_name").value);
      data.append('last_name', document.getElementById("last_name").value);
      data.append('email', document.getElementById("email").value);
      data.append('phone_number', document.getElementById("phone_number").value);
      data.append('age', document.getElementById("age").value);
      data.append('blood_type', document.getElementById("blood_type").value);
      data.append('gender', "M");
  
      if (patientImage) {
        data.append('image', patientImage);  // Append image if it exists
      }
  
      try {
        const response = await fetch(url, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`, // Ensure you have the token defined
          },
          body: data, // Use FormData as the body
        });
  
        if (response.ok) {
          // Update local storage with the new user details
          const updatedUserData = { ...getUserData(), user: formData }; // Adjust as needed
          localStorage.setItem("userData", JSON.stringify(updatedUserData));
  
          // Update state with the new user details
          setUserData(updatedUserData);
          fetchPatientProfile()
          toast.success("Details updated successfully");
          console.log("Details updated successfully");
        } else {
          // Parse the error response to extract field-specific error messages
          const errorData = await response.json();
  
          // Check for specific error fields and show them
          if (errorData.phone_number) {
            toast.error(`Phone number error: ${errorData.phone_number[0]}`);
          } else if (errorData.email) {
            toast.error(`Email error: ${errorData.email[0]}`);
          } else {
            toast.error(errorData.detail || "Failed to update details.");
          }
  
          console.error("Failed to update details:", errorData);
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("An unexpected error occurred.");
      }
    };
  // const updateDetails = async () => {
  //   const url = `${process.env.REACT_APP_API_URL}/user/update/`;

  //   const data = {
  //     first_name: document.getElementById("first_name").value,
  //     last_name: document.getElementById("last_name").value,
  //     email: document.getElementById("email").value,
  //     phone_number: document.getElementById("phone_number").value,
  //     age: document.getElementById("age").value,
  //     blood_type: document.getElementById("blood_type").value,
  //     gender: "M",
  //   };

  //   try {
  //     const response = await fetch(url, {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify(data),
  //     });

  //     if (response.ok) {
  //       // Update local storage with the new user details
  //       const updatedUserData = { ...getUserData(), user: data };
  //       localStorage.setItem("userData", JSON.stringify(updatedUserData));

  //       // Update state with the new user details
  //       setUserData(updatedUserData);

  //       toast.success("Details updated successfully");
  //       console.log("Details updated successfully");
  //     } else {
  //       // Parse the error response to extract field-specific error messages
  //       const errorData = await response.json();

  //       // Check for specific error fields and show them
  //       if (errorData.phone_number) {
  //         toast.error(`Phone number error: ${errorData.phone_number[0]}`);
  //       } else if (errorData.email) {
  //         toast.error(`Email error: ${errorData.email[0]}`);
  //       } else {
  //         toast.error("Failed to update details.");
  //       }

  //       console.error("Failed to update details:", errorData);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //     toast.error("An unexpected error occurred.");
  //   }
  // };

  const getBadgeVariant = (status) => {
    switch (status) {
      case "pending":
        return "primary";
      case "confirmed":
        return "success";
      case "rejected":
        return "danger";
      case "rescheduled":
        return "warning";
      case "completed":
        return "dark";
      case "referred":
        return "info";
      default:
        return "secondary";
    }
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  return (
    <>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container"
      />

      <section className="bg-hero">
        <div className="container">
          <div className="row mt-lg-5">
            {activeIndex === 2 && (
              <div className="col-md-6 col-lg-4">
                <div className="rounded shadow overflow-hidden sticky-bar">
                  <div className="card border-0">
                    <img src={bg1} className="img-fluid" alt="" />
                  </div>

                  <div className="text-center avatar-profile margin-nagative mt-n5 position-relative pb-4 border-bottom">
                    {user ? (
                      <img
                        src={user.picture}
                        className="rounded-circle shadow-md avatar avatar-md-md"
                        alt=""
                      />
                    ) : (
                      <img
                      src={userData?.user.patient_image ? `${apiUrl}${userData.user.patient_image}` : imageUrl || client1}

                        // src={userData?.user.patient_image || client1}
                        className="rounded-circle shadow-md avatar avatar-md-md"
                        alt=""
                      />
                    )}

                    {user ? (
                      <h5 className="mt-3 mb-1">
                        {user?.first_name} {} {user?.last_name}
                      </h5>
                    ) : (
                      <h5 className="mt-3 mb-1">
                        {userData?.user?.first_name} {}{" "}
                        {userData?.user?.last_name}
                      </h5>
                    )}

                    <p className="text-muted mb-0">
                      {userData?.user?.age} Years old
                    </p>
                  </div>

                  <div className="list-unstyled p-4">
                    <div className="progress-box mb-4">
                      <h6 className="title">Complete your profile</h6>
                      <div className="progress">
                        <div
                          className="progress-bar position-relative bg-primary"
                          style={{ width: "89%" }}
                        >
                          <div className="progress-value d-block text-muted h6">
                            89%
                          </div>
                        </div>
                      </div>
                    </div>
                    {profileData.map((item, index) => {
                      let Icon = item.icon;
                      return (
                        <div
                          className="d-flex align-items-center mt-3"
                          key={index}
                        >
                          <Icon className="align-text-bottom text-primary h5 mb-0 me-2" />
                          <h6 className="mb-0">{item.title}</h6>
                          <p className="text-muted mb-0 ms-2">{item.name}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            {/* <div className="col-lg-8 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0"> */}
            <div
              className={`col-lg-${activeIndex === 2 ? 8 : 12} col-md-${
                activeIndex === 2 ? 6 : 12
              } mt-4 mt-sm-0 pt-2 pt-sm-0`}
            >
              <div className="card border-0 shadow overflow-hidden">
                <ul className="nav nav-pills nav-justified flex-column flex-sm-row rounded-0 shadow overflow-hidden bg-light mb-0">
                  <li className="nav-item">
                    <Link
                      className={`${
                        activeIndex === 1 ? "active" : ""
                      } nav-link rounded-0`}
                      to="#"
                      onClick={() => setActiveIndex(1)}
                    >
                      <div className="text-center pt-1 pb-1">
                        <h5 className="title fw-normal mb-0">
                          Recent Appointments
                        </h5>
                      </div>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className={`${
                        activeIndex === 2 ? "active" : ""
                      } nav-link rounded-0`}
                      to="#"
                      onClick={() => setActiveIndex(2)}
                    >
                      <div className="text-center pt-1 pb-1">
                        <h5 className="title fw-normal mb-0">
                          Profile Settings
                        </h5>
                      </div>
                    </Link>
                  </li>
                </ul>

                <div className="tab-content p-4">
                  {activeIndex === 1 ? (
                    <div className="tab-pane fade show active">
                      <h5 className="mb-0">Introduction:</h5>

                      <p className="text-muted mt-4 mb-0">
                        In our commitment to providing timely and comprehensive
                        care, we present the recent appointments of our
                        patients. This section will display a range of
                        appointment statuses, including confirmed and pending,
                        to keep you informed about your healthcare journey.
                      </p>

                      <div className="row">
                        {/* <div className="col-lg-6 col-12 mt-4"> */}
                        <div className="col-12 mt-4">
                          <h5>Appointment List</h5>
                          <div className="d-flex flex-column mb-3">
                            {/* First row: Previous, Today, Next, Date Filter, and Search */}
                            <div className="d-flex flex-wrap align-items-center mb-3">
                              <button
                                className="btn btn-outline-primary me-2 mb-2 mb-md-0"
                                onClick={handlePreviousDay}
                              >
                                &lt; Previous
                              </button>
                              <button
                                className="btn btn-outline-primary me-2 mb-2 mb-md-0"
                                onClick={handleToday}
                              >
                                Today
                              </button>
                              <button
                                className="btn btn-outline-primary me-2 mb-2 mb-md-0"
                                onClick={handleNextDay}
                              >
                                Next &gt;
                              </button>
                              <input
                                type="date"
                                className="form-control me-3 mb-2 mb-md-0"
                                style={{ flex: 1, maxWidth: "100%" }}
                                value={
                                  selectedDate
                                    ? selectedDate.toISOString().split("T")[0]
                                    : ""
                                }
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setSelectedDate(
                                    value ? new Date(value) : null
                                  );
                                }}
                              />
                              <input
                                type="text"
                                className="form-control mb-2 mb-md-0"
                                placeholder="Search by name..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                style={{ flex: 1, maxWidth: "100%" }}
                              />
                            </div>

                            {/* Second row: Status filters */}
                            <hr style={{ margin: "0", borderColor: "#ccc" }} />
                            <div className="btn-group d-flex flex-wrap">
                              {[
                                {
                                  status: "confirmed",
                                  icon: <FaCheck />,
                                  color: "#28a745",
                                },
                                {
                                  status: "rejected",
                                  icon: <FaTimes />,
                                  color: "#dc3545",
                                },
                                {
                                  status: "rescheduled",
                                  icon: <FaCalendarAlt />,
                                  color: "#ffc107",
                                },
                                {
                                  status: "pending",
                                  icon: <FaClock />,
                                  color: "#007bff",
                                },
                                {
                                  status: "completed",
                                  icon: <FaCheckCircle />,
                                  color: "#343a40",
                                },
                                {
                                  status: "referred",
                                  icon: <FaUserPlus />,
                                  color: "#17a2b8",
                                },
                                {
                                  status: "cancelled",
                                  icon: <FaBan />,
                                  color: "#6c757d",
                                },
                              ].map(({ status, icon, color }) => (
                                <button
                                  key={status}
                                  style={{
                                    backgroundColor:
                                      filterStatus === status ? color : "#fff",
                                    color:
                                      filterStatus === status ? "#fff" : "#000",
                                    padding: "0.5rem 1rem",
                                    fontSize: "0.7rem",
                                    border: "none",
                                    borderRadius: "0",
                                    margin: 0,
                                    borderBottom: "2px solid transparent",
                                  }}
                                  className={`btn btn-${getBadgeVariant(
                                    status
                                  )} btn-sm me-0 mb-2 mb-sm-0`}
                                  onClick={() => handleFilterChange(status)}
                                >
                                  {icon}{" "}
                                  {status.charAt(0).toUpperCase() +
                                    status.slice(1)}
                                </button>
                              ))}

                              <button
                                style={{
                                  backgroundColor: !filterStatus
                                    ? "#6c757d"
                                    : "#fff",
                                  color: !filterStatus ? "#fff" : "#000",
                                  padding: "0.5rem 1rem",
                                  fontSize: "0.7rem",
                                  border: "none",
                                  borderRadius: "0",
                                  margin: 0,
                                  borderBottom: "2px solid transparent",
                                }}
                                className="btn btn-secondary btn-sm me-0 mb-2 mb-sm-0"
                                onClick={() => handleFilterChange("")}
                              >
                                All
                              </button>
                            </div>
                            <hr style={{ margin: "0", borderColor: "#ccc" }} />
                          </div>

                          {/* <div className="d-flex flex-column flex-sm-row align-items-center mb-3">
                           
                            <div className="d-flex flex-wrap align-items-center mb-3">
    <button
        className="btn btn-outline-primary me-2 mb-2 mb-md-0"
        onClick={handlePreviousDay}
    >
        &lt; Previous
    </button>
    <button
        className="btn btn-outline-primary me-2 mb-2 mb-md-0"
        onClick={handleToday}
    >
        Today
    </button>
    <button
        className="btn btn-outline-primary me-2 mb-2 mb-md-0"
        onClick={handleNextDay}
    >
        Next &gt;
    </button>
    
    <input
        type="date"
        className="form-control me-3 mb-2 mb-md-0"
        style={{ flex: 1, maxWidth: "100%" }} 
        value={selectedDate ? selectedDate.toISOString().split('T')[0] : ''} 
        onChange={(e) => {
            const value = e.target.value;
            setSelectedDate(value ? new Date(value) : null);
        }} 
    />
    
    <input
        type="text"
        className="form-control mb-2 mb-md-0"
        placeholder="Search by name..."
        value={searchQuery}
        onChange={handleSearchChange}
        style={{ flex: 1, maxWidth: "100%" }} 
    />
</div>

                           

                            <div className="btn-group d-flex flex-wrap">
                              {[
                                {
                                  status: "confirmed",
                                  icon: <FaCheck />,
                                  color: "#28a745",
                                },
                                {
                                  status: "rejected",
                                  icon: <FaTimes />,
                                  color: "#dc3545",
                                },
                                {
                                  status: "rescheduled",
                                  icon: <FaCalendarAlt />,
                                  color: "#ffc107",
                                },
                                {
                                  status: "pending",
                                  icon: <FaClock />,
                                  color: "#007bff",
                                },
                                {
                                  status: "completed",
                                  icon: <FaCheckCircle />,
                                  color: "#343a40",
                                },
                                {
                                  status: "referred",
                                  icon: <FaUserPlus />,
                                  color: "#17a2b8",
                                },
                                {
                                  status: "cancelled",
                                  icon: <FaBan />,
                                  color: "#6c757d",
                                },
                              ].map(({ status, icon, color }) => (
                                <button
                                  key={status}
                                  style={{
                                    backgroundColor:
                                      filterStatus === status ? color : "#fff",
                                    color:
                                      filterStatus === status ? "#fff" : "#000",
                                    padding: "0.5rem 1rem",
                                    fontSize: "0.7rem",
                                    border: "none",
                                    borderRadius: "0",
                                    margin: 0,
                                    borderBottom: "2px solid transparent",
                                  }}
                                  className={`btn btn-${getBadgeVariant(
                                    status
                                  )} btn-sm me-0 mb-2 mb-sm-0`}
                                  onClick={() => handleFilterChange(status)}
                                >
                                  {icon}{" "}
                                  {status.charAt(0).toUpperCase() +
                                    status.slice(1)}
                                </button>
                              ))}

                              <button
                                style={{
                                  backgroundColor: !filterStatus
                                    ? "#6c757d"
                                    : "#fff",
                                  color: !filterStatus ? "#fff" : "#000",
                                  padding: "0.5rem 1rem",
                                  fontSize: "0.7rem",
                                  border: "none",
                                  borderRadius: "0",
                                  margin: 0,
                                  borderBottom: "2px solid transparent",
                                }}
                                className={`btn btn-secondary btn-sm me-0 mb-2 mb-sm-0`}
                                onClick={() => handleFilterChange("")}
                              >
                                All
                              </button>
                            </div>
                          </div> */}

                          {loading ? (
                            <div>
                              <SkeletonLoader />
                            </div>
                          ) : (
                            <>
  {filteredData.map((item, index) => {
    const Icon = iconMap[item.department] || RiHeartPulseLine;
    return (
      <div
        className="d-flex flex-column flex-sm-row justify-content-between align-items-center rounded p-3 shadow mt-3"
        key={index}
      >
        <Icon
          style={{ fontSize: "2rem" }}
          className="h3 fw-normal text-primary mb-2 mb-sm-0"
        />
        <div className="flex-grow-1 overflow-hidden ms-2 mb-2 mb-sm-0">
          <h6 className="mb-1">
            {item.doctor_first_name} {item.doctor_last_name}
          </h6>
          <p className="text-muted mb-0 text-truncate small">
            {item.reason}
          </p>
        </div>
        
        <div className="d-flex flex-column flex-sm-row align-items-center mt-2 mt-sm-0">
          <span
            className="d-block mb-2 mb-sm-0 me-sm-2"
          >
            {formatTime(item.appointment_time)}
          </span>
          <span
            className="d-block mb-2 mb-sm-0 me-sm-2"
          >
            {formatDate(item.appointment_date)}
          </span>
          <span
            className="d-block mb-2 mb-sm-0 me-sm-2"
          >
            <Badge
              bg={
                item.payment_status === "successful"
                  ? "success"
                  : item.payment_status === "No Payment"
                  ? "danger"
                  : "secondary"
              }
            >
              {item.payment_status === "successful"
                ? "Paid"
                : item.payment_status === "No Payment"
                ? "Unpaid"
                : item.payment_status}
            </Badge>
          </span>
          <span
            className="d-block mb-2 mb-sm-0"
          >
            <Badge
              bg={getBadgeVariant(item.status)}
              style={{
                borderRadius: "10px",
                fontSize: ".7rem",
                padding: "0.5rem 1rem",
              }}
            >
              {item.status}
            </Badge>
          </span>
        </div>

        <Crud
          appointment={item}
          allPrescription={allPrescription}
          fetchData={fetchData}
        />
      </div>
    );
  })}
</>

                            // <>
                            //   {filteredData.map((item, index) => {
                            //     const Icon =
                            //       iconMap[item.department] || RiHeartPulseLine;
                            //     return (
                            //       <div
                            //         className="d-flex flex-column flex-sm-row justify-content-between align-items-center rounded p-3 shadow mt-3"
                            //         key={index}
                            //       >
                            //         <Icon
                            //           style={{ fontSize: "2rem" }}
                            //           className="h3 fw-normal text-primary mb-2 mb-sm-0"
                            //         />
                            //         <div className="flex-1 overflow-hidden ms-2 mb-2 mb-sm-0">
                            //           <h6 className="mb-1">
                            //             {item.doctor_first_name}{" "}
                            //             {item.doctor_last_name}
                            //           </h6>
                            //           <p className="text-muted mb-0 text-truncate small">
                            //             {item.reason}
                            //           </p>
                            //         </div>
                            //         <div className="flex-1 overflow-hidden ms-2 mb-2 mb-sm-0"></div>

                            //         <span
                            //           className="d-block d-sm-inline mb-2 mb-sm-0"
                            //           style={{ marginRight: "1rem" }}
                            //         >
                            //           {formatTime(item.appointment_time)}
                            //         </span>
                            //         <span
                            //           className="d-block d-sm-inline mb-2 mb-sm-0"
                            //           style={{ marginLeft: "1rem" }}
                            //         >
                            //           {formatDate(item.appointment_date)}
                            //         </span>
                            //         <span
                            //           className="d-block d-sm-inline mb-2 mb-sm-0"
                            //           style={{ marginLeft: "1rem" }}
                            //         >
                            //           <Badge
                            //             bg={
                            //               item.payment_status === "successful"
                            //                 ? "success"
                            //                 : item.payment_status ===
                            //                   "No Payment"
                            //                 ? "danger"
                            //                 : "secondary"
                            //             }
                            //           >
                            //             {item.payment_status === "successful"
                            //               ? "Paid"
                            //               : item.payment_status === "No Payment"
                            //               ? "Unpaid"
                            //               : item.payment_status}
                            //           </Badge>
                            //         </span>
                            //         <span
                            //           className="d-block d-sm-inline mb-2 mb-sm-0"
                            //           style={{ marginLeft: "1rem" }}
                            //         >
                            //           <Badge
                            //             bg={getBadgeVariant(item.status)}
                            //             style={{
                            //               borderRadius: "10px",
                            //               fontSize: ".7rem",
                            //               padding: "0.5rem 1rem",
                            //             }}
                            //           >
                            //             {item.status}
                            //           </Badge>
                            //         </span>
                            //         <Crud
                            //           appointment={item}
                            //           allPrescription={allPrescription}
                            //           fetchData={fetchData}
                            //         />
                            //       </div>
                            //     );
                            //   })}
                            // </>
                          )}
                        </div>

                        <Modal
                          show={show}
                          onHide={() => setShow(false)}
                          size="lg"
                          centered
                        >
                          <Modal.Header closeButton>
                            <Modal.Title className="h5">
                              Patient Invoice
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="modal-body p-3 pt-4">
                              <div className="row mb-4">
                                <div className="col-lg-8 col-md-6">
                                  <img src={logoDark} height="22" alt="" />
                                  <h6 className="mt-4 pt-2">Address :</h6>
                                  <small className="text-muted mb-0">
                                    1419 Riverwood Drive, <br />
                                    Redding, CA 96001
                                  </small>
                                </div>

                                <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                  <ul className="list-unstyled">
                                    <li className="d-flex ms-0">
                                      <small className="mb-0 text-muted">
                                        Invoice no. :{" "}
                                      </small>
                                      <small className="mb-0 text-dark">
                                        &nbsp;&nbsp;#54638990jnn
                                      </small>
                                    </li>
                                    <li className="d-flex ms-0 mt-2">
                                      <small className="mb-0 text-muted">
                                        Email :{" "}
                                      </small>
                                      <small className="mb-0">
                                        &nbsp;&nbsp;
                                        <Link
                                          to="mailto:contact@example.com"
                                          className="text-dark"
                                        >
                                          info@doctris.com
                                        </Link>
                                      </small>
                                    </li>
                                    <li className="d-flex ms-0 mt-2">
                                      <small className="mb-0 text-muted">
                                        Phone :{" "}
                                      </small>
                                      <small className="mb-0">
                                        &nbsp;&nbsp;
                                        <Link
                                          to="tel:+152534-468-854"
                                          className="text-dark"
                                        >
                                          (+12) 1546-456-856
                                        </Link>
                                      </small>
                                    </li>
                                    <li className="d-flex ms-0 mt-2">
                                      <small className="mb-0 text-muted">
                                        Website :{" "}
                                      </small>
                                      <small className="mb-0">
                                        &nbsp;&nbsp;
                                        <Link to="#" className="text-dark">
                                          www.doctris.com
                                        </Link>
                                      </small>
                                    </li>
                                    <li className="d-flex ms-0 mt-2">
                                      <small className="mb-0 text-muted">
                                        Patient Name :{" "}
                                      </small>
                                      <small className="mb-0">
                                        &nbsp;&nbsp;Mary Skeens
                                      </small>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div className="pt-4 border-top">
                                <div className="row">
                                  <div className="col-lg-8 col-md-6">
                                    <h5 className="text-muted fw-bold">
                                      Invoice{" "}
                                      <span className="badge rounded-pill bg-soft-success fw-normal ms-2">
                                        Paid
                                      </span>
                                    </h5>
                                    <h6>Surgery (Gynecology)</h6>
                                  </div>

                                  <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                    <ul className="list-unstyled">
                                      <li className="d-flex mt-2 ms-0">
                                        <small className="mb-0 text-muted">
                                          Issue Dt. :{" "}
                                        </small>
                                        <small className="mb-0 text-dark">
                                          &nbsp;&nbsp;25th Sep. 2020
                                        </small>
                                      </li>

                                      <li className="d-flex mt-2 ms-0">
                                        <small className="mb-0 text-muted">
                                          Due Dt. :{" "}
                                        </small>
                                        <small className="mb-0 text-dark">
                                          &nbsp;&nbsp;11th Oct. 2020
                                        </small>
                                      </li>

                                      <li className="d-flex mt-2 ms-0">
                                        <small className="mb-0 text-muted">
                                          Dr. Name :{" "}
                                        </small>
                                        <small className="mb-0 text-dark">
                                          &nbsp;&nbsp;Dr. Calvin Carlo
                                        </small>
                                      </li>
                                    </ul>
                                  </div>
                                </div>

                                <div className="invoice-table pb-4">
                                  <div className="table-responsive shadow rounded mt-4">
                                    <table className="table table-center invoice-tb mb-0">
                                      <thead>
                                        <tr>
                                          <th
                                            scope="col"
                                            className="text-start border-bottom p-3"
                                            style={{ minWidth: "60px" }}
                                          >
                                            No.
                                          </th>
                                          <th
                                            scope="col"
                                            className="text-start border-bottom p-3"
                                            style={{ minWidth: "220px" }}
                                          >
                                            Item
                                          </th>
                                          <th
                                            scope="col"
                                            className="text-center border-bottom p-3"
                                            style={{ minWidth: "60px" }}
                                          >
                                            Qty
                                          </th>
                                          <th
                                            scope="col"
                                            className="border-bottom p-3"
                                            style={{ minWidth: "130px" }}
                                          >
                                            Rate
                                          </th>
                                          <th
                                            scope="col"
                                            className="border-bottom p-3"
                                            style={{ minWidth: "130px" }}
                                          >
                                            Total
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <th
                                            scope="row"
                                            className="text-start p-3"
                                          >
                                            1
                                          </th>
                                          <td className="text-start p-3">
                                            Hospital Charges
                                          </td>
                                          <td className="text-center p-3">1</td>
                                          <td className="p-3">$ 125</td>
                                          <td className="p-3">$ 125</td>
                                        </tr>
                                        <tr>
                                          <th
                                            scope="row"
                                            className="text-start p-3"
                                          >
                                            2
                                          </th>
                                          <td className="text-start p-3">
                                            Medicine
                                          </td>
                                          <td className="text-center p-3">1</td>
                                          <td className="p-3">$ 245</td>
                                          <td className="p-3">$ 245</td>
                                        </tr>
                                        <tr>
                                          <th
                                            scope="row"
                                            className="text-start p-3"
                                          >
                                            3
                                          </th>
                                          <td className="text-start p-3">
                                            Special Visit Fee(Doctor)
                                          </td>
                                          <td className="text-center p-3">1</td>
                                          <td className="p-3">$ 150</td>
                                          <td className="p-3">$ 150</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-4 col-md-5 ms-auto">
                                      <ul className="list-unstyled h6 fw-normal mt-4 mb-0 ms-md-5 ms-lg-4">
                                        <li className="text-muted d-flex justify-content-between pe-3 ms-0">
                                          Subtotal :<span>$ 520</span>
                                        </li>
                                        <li className="text-muted d-flex justify-content-between pe-3 ms-0">
                                          Taxes :<span> 0</span>
                                        </li>
                                        <li className="d-flex justify-content-between pe-3 ms-0">
                                          Total :<span>$ 520</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>

                                <div className="border-top pt-4">
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <div className="text-sm-start text-muted text-center">
                                        <small className="mb-0">
                                          Customer Services :{" "}
                                          <Link
                                            to="tel:+152534-468-854"
                                            className="text-warning"
                                          >
                                            (+12) 1546-456-856
                                          </Link>
                                        </small>
                                      </div>
                                    </div>

                                    <div className="col-sm-6">
                                      <div className="text-sm-end text-muted text-center">
                                        <small className="mb-0">
                                          <Link to="#" className="text-primary">
                                            Terms & Conditions
                                          </Link>
                                        </small>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>

                      {/* <h5 className="mb-0 mt-4 pt-2">Contact us</h5>
                      <div className="row">
                        {adminFeature.slice(0, 2).map((item, index) => {
                          let Icon = item.icon;
                          return (
                            <div
                              className="col-md-12 col-lg-6 mt-4"
                              key={index}
                            >
                              <div className="card features feature-primary text-center border-0 p-4 rounded shadow">
                                <div className="icon text-center rounded-lg mx-auto">
                                  <Icon className="align-middle h3" />
                                </div>
                                <div className="card-body p-0 mt-3">
                                  <Link
                                    to="#"
                                    className="title text-dark h6 d-block"
                                  >
                                    {item.title}
                                  </Link>
                                  <Link to="#" className="link">
                                    Read more{" "}
                                    <FiArrowRight className="ri-arrow-right-line align-middle" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div> */}
                    </div>
                  ) : (
                    ""
                  )}
                  {activeIndex === 2 ? (
                    <div className="tab-pane fade show active">
                      <h5 className="mb-0">Personal Information :</h5>
                      <div className="row align-items-center mt-4">
                        {/* <div className="col-lg-2 col-md-4">
                          <img
                            src={client1}
                            className="avatar avatar-md-md rounded-pill shadow mx-auto d-block"
                            alt=""
                          />
                        </div>

                        <div className="col-lg-5 col-md-8 text-center text-md-start mt-4 mt-sm-0">
                          <h6>Upload your picture</h6>
                          <p className="text-muted mb-0">
                            For best results, use an image at least 256px by
                            256px in either .jpg or .png format
                          </p>
                        </div> */}

                        {/* <div className="col-lg-5 col-md-12 text-lg-right text-center mt-4 mt-lg-0">
                          <Link to="#" className="btn btn-primary">
                            Upload
                          </Link>
                          <Link to="#" className="btn btn-soft-primary ms-2">
                            Remove
                          </Link>
                        </div> */}
                      </div>

                      <form className="mt-4">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label className="form-label">First Name</label>
                              <input
                                name="first_name"
                                id="first_name"
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                                value={formData.first_name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label className="form-label">Last Name</label>
                              <input
                                name="last_name"
                                id="last_name"
                                type="text"
                                className="form-control"
                                placeholder="Last Name"
                                value={formData.last_name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label className="form-label">Your Email</label>
                              <input
                                name="email"
                                id="email"
                                type="email"
                                className="form-control"
                                placeholder="Your email"
                                value={formData.email}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label className="form-label">Phone no.</label>
                              <input
                                name="phone_number"
                                id="phone_number"
                                type="number"
                                className="form-control"
                                placeholder="Phone no."
                                value={formData.phone_number}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label className="form-label">Age</label>
                              <input
                                name="age"
                                id="age"
                                type="number"
                                className="form-control"
                                placeholder="Age"
                                value={formData.age}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                                                <label className="form-label">Upload Image (optional)</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    accept="image/*"  // Accept only image files
                                                    onChange={handleImageChange}
                                                    name="image"  // Change name to patient_image
                                                    id="image"    // Change id to patient_image
                                                />
                                            </div>
                          {/* <div className="col-md-6 mb-3">
          <label className="form-label">Upload Image (optional)</label>
          <input
            name="patient_image"  // Change name to patient_image
            id="patient_image"    // Change id to patient_image
            type="file"
            className="form-control"
            accept="image/*"  // Accept only image files
            onChange={handleImageChange}
          />
        </div> */}
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label className="form-label">Blood Group</label>
                              <select
                                name="blood_type"
                                id="blood_type"
                                className="form-control"
                                value={formData.blood_type}
                                onChange={handleChange}
                              >
                                <option value="">Select Blood Group</option>
                                <option value="A+">A+</option>
                                <option value="A-">A-</option>
                                <option value="B+">B+</option>
                                <option value="B-">B-</option>
                                <option value="AB+">AB+</option>
                                <option value="AB-">AB-</option>
                                <option value="O+">O+</option>
                                <option value="O-">O-</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={updateDetails}
                            >
                              Update Details
                            </button>
                          </div>
                        </div>
                      </form>
                      <div className="rounded shadow mt-4">
                        <div className="p-4 border-bottom">
                          <h5 className="mb-0">Change Password :</h5>
                        </div>

                        <div className="p-4">
                          <form className="mt-4">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Current Password
                                  </label>
                                  <input
                                    name="current_password"
                                    id="current_password"
                                    type="password"
                                    className="form-control"
                                    placeholder="Current password"
                                    value={currentPassword}
                                    onChange={handlePasswordChange}
                                  />
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <div className="mb-3">
                                  <label className="form-label">
                                    New Password
                                  </label>
                                  <input
                                    name="new_password"
                                    id="new_password"
                                    type="password"
                                    className="form-control"
                                    placeholder="New password"
                                    value={newPassword}
                                    onChange={handlePasswordChange}
                                  />
                                  <ul className="mt-2">
                                    {passwordErrors.map((error, index) => (
                                      <li key={index} className="text-danger">
                                        {error}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>

                              <div className="col-lg-12">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Confirm New Password
                                  </label>
                                  <input
                                    name="confirm_new_password"
                                    id="confirm_new_password"
                                    type="password"
                                    className="form-control"
                                    placeholder="Re-type New password"
                                    value={confirmNewPassword}
                                    onChange={handlePasswordChange}
                                  />
                                </div>
                              </div>

                              {error && (
                                <div className="col-lg-12 mb-3">
                                  <div className="alert alert-danger">
                                    {error}
                                  </div>
                                </div>
                              )}

                              <div className="col-lg-12 mt-2 mb-0">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={changePassword}
                                >
                                  Save Password
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      {/* <form className="mt-4">
  <div className="row">
    <div className="col-lg-12">
      <div className="mb-3">
        <label className="form-label">Current Password</label>
        <input
          name="current_password"
          id="current_password"
          type="password"
          className="form-control"
          placeholder="Current password"
        />
      </div>
    </div>

    <div className="col-lg-12">
      <div className="mb-3">
        <label className="form-label">New Password</label>
        <input
          name="new_password"
          id="new_password"
          type="password"
          className="form-control"
          placeholder="New password"
        />
      </div>
    </div>

    <div className="col-lg-12">
      <div className="mb-3">
        <label className="form-label">Confirm New Password</label>
        <input
          name="confirm_new_password"
          id="confirm_new_password"
          type="password"
          className="form-control"
          placeholder="Re-type New password"
        />
      </div>
    </div>

    {error && (
      <div className="col-lg-12 mb-3">
        <div className="alert alert-danger">
          {error}
        </div>
      </div>
    )}

    <div className="col-lg-12 mt-2 mb-0">
      <button
        type="button"
        className="btn btn-primary"
        onClick={changePassword}
      >
        Save Password
      </button>
    </div>
  </div>
</form> */}

                      {/* <form className="mt-4">
  <div className="row">
    <div className="col-lg-12">
      <div className="mb-3">
        <label className="form-label">Current Password</label>
        <input
          name="current_password"
          id="current_password"
          type="password"
          className="form-control"
          placeholder="Current password"
        />
      </div>
    </div>

    <div className="col-lg-12">
      <div className="mb-3">
        <label className="form-label">New Password</label>
        <input
          name="new_password"
          id="new_password"
          type="password"
          className="form-control"
          placeholder="New password"
        />
      </div>
    </div>

    <div className="col-lg-12">
      <div className="mb-3">
        <label className="form-label">Confirm New Password</label>
        <input
          name="confirm_new_password"
          id="confirm_new_password"
          type="password"
          className="form-control"
          placeholder="Re-type New password"
        />
      </div>
    </div>

    <div className="col-lg-12 mt-2 mb-0">
      <button
        type="button"
        className="btn btn-primary"
        onClick={changePassword}
      >
        Save Password
      </button>
    </div>
  </div>
</form> */}

                      <div className="mt-4 pt-2">
                        <h5 className="mb-0 text-danger">Delete Account :</h5>
                        <p className="mb-0 mt-4">
                          Do you want to delete the account? Please press the
                          button below "Delete Account"
                        </p>
                        <div className="mt-4">
                          <button
                            className="btn btn-danger"
                            onClick={handleDelete}
                          >
                            Delete Account
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
      <Footer />
      <ScrollTop />
    </>
  );
}
