import React from "react";
import { Route, Routes } from "react-router-dom";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/style.scss";
import "./assets/scss/bootstrap.scss";
import "./assets/css/materialdesignicons.min.css";
import store from "./store/index";
import { Provider } from "react-redux";
import Index from "./pages/index";
import IndexTwo from "./pages/index/index-two";
import IndexThree from "./pages/index/index-three";
import DoctorDashBoard from "./pages/dashboard/doctor-dashboard";
import DoctorAppointment from "./pages/dashboard/doctor-appointment";
import DoctorSchedule from "./pages/dashboard/doctor-schedule";
import Invoice from "./pages/dashboard/invoices";
import DoctorMessages from "./pages/dashboard/doctor-messages";
import DoctorProfile from "./pages/dashboard/doctor-profile";
import DoctorProfileNew from "./pages/dashboard/doctor-profile-new.js";
import DoctorProfileSettimg from "./pages/dashboard/doctor-profile-setting";
import DoctorProfileEdit from "./pages/doctor/DoctorProfileEdit.js";
import PatientList from "./pages/dashboard/patient-list";
import PatientReview from "./pages/dashboard/patient-review";
import DoctorChat from "./pages/dashboard/doctor-chat";
import DoctorTeamOne from "./pages/doctor/doctor-team-one";
import DoctorTeamTwo from "./pages/doctor/doctor-team-two.js";
import DoctorTeamThree from "./pages/doctor/doctor-team-three";
import PatientDashboard from "./pages/patient/patient-dashboard";
import PatientProfile from "./pages/patient/patient-profile";
import AddPatient from "./components/patient/AddPatient.jsx";
import BookingAppointment from "./pages/patient/booking-appointment";
import PatientInvoice from "./pages/patient/patient-invoice";
import Pharmacy from "./pages/pharmacy/pharmacy";
import PharmacyShop from "./pages/pharmacy/pharmacy-shop";
import PharmacyProductDetail from "./pages/pharmacy/pharmacy-product-detail";
import ShopCart from "./pages/pharmacy/pharmacy-shop-cart";
import CheckOut from "./pages/pharmacy/pharmacy-checkout";
import PharmacyAccount from "./pages/pharmacy/pharmacy-account";
import AboutUs from "./pages/aboutus";
import Departments from "./pages/departments";
import Faqs from "./pages/faqs";
import Blogs from "./pages/blogs";
import BlogDetail from "./pages/blog-detail";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import Error from "./pages/error";
import Contact from "./pages/contact";
import Login from "./pages/login";
import Signup from "./pages/signup";
import ForgotPassword from "./pages/forgot-password";
import DoctorDetails from "./pages/doctor/DoctorDetails";
import ProtectedRoute from "./components/ProtectedRoute"; // Import the ProtectedRoute component
import EmailVerification from "./components/EmailVerification/index.js";
import ResetPassword from "./components/ResetPassword/index.js";
import TimeTable from "./components/RegisterDoctor/TimeTable.jsx";
import PatientDetailPage from "./pages/dashboard/PatientDetailPage.js";
import RazorPayPayment from "./pages/common/RazorPayPayment.js";
import DoctorTimetablePage from "./pages/doctor/DoctorTimetablePage.js";
import UnavailableDates from "./pages/doctor/UnavailableDates";
import StaffManagement from "./pages/doctor/StaffManagement.js";
import Cliniclist from "./pages/doctor/Cliniclist.js";
import HealthMetrics from "./pages/patient/HealthMetrics.js";
import JitsiMeeting from "./components/JItsiMeeting/index.js";
import AppointmentPage from "./pages/doctor/AppointmentPage.js";
function App() {
  return (
    <Provider store={store}>
      <Routes>
        <Route
          path="/"
          element={
            // <ProtectedRoute>
            <Index />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/index"
          element={
            // <ProtectedRoute>
            <Index />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/index-two"
          element={
            // <ProtectedRoute>
            <IndexTwo />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/index-three"
          element={
            // <ProtectedRoute>
            <IndexThree />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/videoconference"
          element={
            // <ProtectedRoute>
            <JitsiMeeting />
            // </ProtectedRoute>
          }
        />

        <Route
          path="/doctor-dashboard"
          element={
            <ProtectedRoute allowedRoles={["doctor", "staff"]}>
              <DoctorDashBoard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/StaffManagement"
          element={
            <ProtectedRoute allowedRoles={["doctor", "staff"]}>
              <StaffManagement />
            </ProtectedRoute>
          }
        />
        <Route path="/unauthorized" element={<Error />} />

        <Route
          path="/doctor/appointments/:id"
          element={
            <ProtectedRoute>
              <DoctorAppointment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-schedule"
          element={
            <ProtectedRoute>
              <DoctorSchedule />
            </ProtectedRoute>
          }
        />
        <Route
          path="/invoices"
          element={
            <ProtectedRoute>
              <Invoice />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-messages"
          element={
            <ProtectedRoute>
              <DoctorMessages />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-profile/:id"
          element={
            // <ProtectedRoute>
            <DoctorProfile />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-profile-edit"
          element={
            // <ProtectedRoute>
            <DoctorProfileEdit />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-profileview/:id"
          element={
            // <ProtectedRoute>
            <DoctorProfileNew />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/clinictimetable/:id"
          element={
            // <ProtectedRoute>
            <DoctorTimetablePage />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/Cliniclist/:id"
          element={
            // <ProtectedRoute>
            <Cliniclist />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-unavailable/:id"
          element={
            // <ProtectedRoute>
            <UnavailableDates />
            // </ProtectedRoute>
          }
        />
        {/* <Route
        path="/doctor-profile/:id/add-patient"
        element={
          <ProtectedRoute>
            <AddPatient />
          </ProtectedRoute>
        }
      /> */}
        <Route
          path="/doctor-profile-setting"
          element={
            <ProtectedRoute>
              <DoctorProfileSettimg />
            </ProtectedRoute>
          }
        />
        <Route
          path="/patient-list"
          element={
            <ProtectedRoute>
              <PatientList />
            </ProtectedRoute>
          }
        />
        <Route
          path="doctor-profile/:id/time-table"
          element={
            <ProtectedRoute>
              <TimeTable />
            </ProtectedRoute>
          }
        />
        <Route
          path="/patient-review"
          element={
            <ProtectedRoute>
              <PatientReview />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-chat"
          element={
            <ProtectedRoute>
              <DoctorChat />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-team-one"
          element={
            <ProtectedRoute>
              <DoctorTeamOne />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-search"
          element={
            // <ProtectedRoute>
            <DoctorTeamTwo />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-team-three"
          element={
            <ProtectedRoute>
              <DoctorTeamThree />
            </ProtectedRoute>
          }
        />
        <Route
          path="/patient-dashboard"
          element={
            <ProtectedRoute>
              <PatientDashboard allowedRoles={["patient"]} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/patient-profile"
          element={
            <ProtectedRoute>
              <PatientProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/booking-appointment"
          element={
            <ProtectedRoute>
              <BookingAppointment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Index />
            </ProtectedRoute>
          }
        />
        <Route
          path="/index"
          element={
            <ProtectedRoute>
              <Index />
            </ProtectedRoute>
          }
        />
        <Route
          path="/index-two"
          element={
            <ProtectedRoute>
              <IndexTwo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/index-three"
          element={
            <ProtectedRoute>
              <IndexThree />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-dashboard"
          element={
            <ProtectedRoute>
              <DoctorDashBoard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor/appointments/:doctorId"
          element={
            <ProtectedRoute>
              <DoctorAppointment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-schedule"
          element={
            <ProtectedRoute>
              <DoctorSchedule />
            </ProtectedRoute>
          }
        />
        <Route
          path="/invoices"
          element={
            <ProtectedRoute>
              <Invoice />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-messages"
          element={
            <ProtectedRoute>
              <DoctorMessages />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-profile/:id"
          element={
            <ProtectedRoute>
              <DoctorProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-profile-setting"
          element={
            <ProtectedRoute>
              <DoctorProfileSettimg />
            </ProtectedRoute>
          }
        />
        <Route
          path="/patient/health-metrics/:patientId"
          element={
            <ProtectedRoute>
              <HealthMetrics />
            </ProtectedRoute>
          }
        />
        <Route
          path="/patient-list"
          element={
            <ProtectedRoute>
              <PatientList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/patient-review"
          element={
            <ProtectedRoute>
              <PatientReview />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-chat"
          element={
            <ProtectedRoute>
              <DoctorChat />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-team-one"
          element={
            <ProtectedRoute>
              <DoctorTeamOne />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-search"
          element={
            <ProtectedRoute>
              <DoctorTeamTwo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-team-three"
          element={
            <ProtectedRoute>
              <DoctorTeamThree />
            </ProtectedRoute>
          }
        />
        <Route
          path="/patient-dashboard"
          element={
            <ProtectedRoute>
              <PatientDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/patient-profile/:id"
          element={
            <ProtectedRoute allowedRoles={["patient"]}>
              <PatientProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/booking-appointment"
          element={
            <ProtectedRoute>
              <BookingAppointment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/patient-details"
          element={
            // <ProtectedRoute>
            <PatientDetailPage />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Index />
            </ProtectedRoute>
          }
        />
        <Route
          path="/index"
          element={
            <ProtectedRoute>
              <Index />
            </ProtectedRoute>
          }
        />
        <Route
          path="/index-two"
          element={
            <ProtectedRoute>
              <IndexTwo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/index-three"
          element={
            <ProtectedRoute>
              <IndexThree />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-dashboard"
          element={
            <ProtectedRoute>
              <DoctorDashBoard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/razorpay/payment/create"
          element={
            <ProtectedRoute>
              <RazorPayPayment />
            </ProtectedRoute>
          }
        />

        <Route
          path="/doctor/appointments/:id"
          element={
            <ProtectedRoute>
              <DoctorAppointment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-schedule"
          element={
            <ProtectedRoute>
              <DoctorSchedule />
            </ProtectedRoute>
          }
        />
        <Route
          path="/invoices"
          element={
            <ProtectedRoute>
              <Invoice />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-messages"
          element={
            <ProtectedRoute>
              <DoctorMessages />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-profile/:id"
          element={
            <ProtectedRoute>
              <DoctorProfile />
            </ProtectedRoute>
          }
        />
          <Route
          path="/appointmentlogin/:id"
          element={
            // <ProtectedRoute>
              <AppointmentPage />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-profile-setting"
          element={
            <ProtectedRoute>
              <DoctorProfileSettimg />
            </ProtectedRoute>
          }
        />
        <Route
          path="/patient-list"
          element={
            <ProtectedRoute>
              <PatientList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/patient-review"
          element={
            <ProtectedRoute>
              <PatientReview />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-chat"
          element={
            <ProtectedRoute>
              <DoctorChat />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-team-one"
          element={
            <ProtectedRoute>
              <DoctorTeamOne />
            </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-search"
          element={
            // <ProtectedRoute>
            <DoctorTeamTwo />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/doctor-team-three"
          element={
            <ProtectedRoute>
              <DoctorTeamThree />
            </ProtectedRoute>
          }
        />
        <Route
          path="/patient-dashboard"
          element={
            <ProtectedRoute>
              <PatientDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/patient-profile/:id"
          element={
            <ProtectedRoute>
              <PatientProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/booking-appointment"
          element={
            <ProtectedRoute>
              <BookingAppointment />
            </ProtectedRoute>
          }
        />
        <Route path="/verify-email/:token" element={<EmailVerification />} />
        <Route
          path="/patient-invoice"
          element={
            <ProtectedRoute>
              <PatientInvoice />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pharmacy"
          element={
            <ProtectedRoute>
              <Pharmacy />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pharmacy-shop"
          element={
            <ProtectedRoute>
              <PharmacyShop />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pharmacy-product-detail"
          element={
            <ProtectedRoute>
              <PharmacyProductDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pharmacy-product-detail/:id"
          element={
            <ProtectedRoute>
              <PharmacyProductDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pharmacy-shop-cart"
          element={
            <ProtectedRoute>
              <ShopCart />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pharmacy-checkout"
          element={
            <ProtectedRoute>
              <CheckOut />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pharmacy-account"
          element={
            <ProtectedRoute>
              <PharmacyAccount />
            </ProtectedRoute>
          }
        />
        <Route
          path="/aboutus"
          element={
            <ProtectedRoute>
              <AboutUs />
            </ProtectedRoute>
          }
        />
        <Route
          path="/departments"
          element={
            <ProtectedRoute>
              <Departments />
            </ProtectedRoute>
          }
        />
        <Route
          path="/faqs"
          element={
            <ProtectedRoute>
              <Faqs />
            </ProtectedRoute>
          }
        />
        <Route
          path="/blogs"
          element={
            <ProtectedRoute>
              <Blogs />
            </ProtectedRoute>
          }
        />
        <Route
          path="/blog-detail"
          element={
            <ProtectedRoute>
              <BlogDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/terms"
          element={
            <ProtectedRoute>
              <Terms />
            </ProtectedRoute>
          }
        />
        <Route
          path="/privacy"
          element={
            <ProtectedRoute>
              <Privacy />
            </ProtectedRoute>
          }
        />
        <Route
          path="/error"
          element={
            <ProtectedRoute>
              <Error />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contact"
          element={
            <ProtectedRoute>
              <Contact />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        {/* <Route path="/password-reset-confirm/:token" element={<ResetPassword />} /> */}
        <Route path="/password-reset-confirm/" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/doctors/:id"
          element={
            <ProtectedRoute>
              <DoctorDetails />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Provider>
  );
}

export default App;
