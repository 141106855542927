// // src/pages/AppointmentPage.js

// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import axios from "axios";
// import { FaUserMd, FaSchool, FaAddressCard } from "react-icons/fa";
// import { RiMapPinLine } from "../../assets/icons/vander";
// import MobileLogin from "../../components/CommonComponents/MobileLogin";
// import SkeletonLoader from "./SkeletonLoader";
// import dr1 from "../../assets/images/doctors/Avatar.jpg";
// import Navbar from "../../components/navbar";
// import Header from "../../components/CommonComponents/Header";

// const AppointmentPage = () => {
//   const [doctors, setDoctors] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const { id } = useParams();
//   const navigate = useNavigate();
//   useEffect(() => {
//     const fetchDoctors = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/doctors/details/${id}/`
//         );

//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }

//         const data = await response.json();

//         // Check if data is in the expected format
//         if (Array.isArray(data)) {
//           setDoctors(data);
//         } else {
//           setDoctors([data]); // Wrap in array if it's a single object
//         }
//       } catch (err) {
//         setError(err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchDoctors();
//   }, [id]);

//   //   useEffect(() => {
//   //     // Fetch doctor details
//   //     const fetchDoctors = async () => {
//   //       try {
//   //         // const response = await axios.get(`${process.env.REACT_APP_API_URL}/doctors/`);
//   //         const response = await fetch(
//   //             `${process.env.REACT_APP_API_URL}/doctors/details/${id}/`
//   //           );
//   //         setDoctors(response.data);
//   //       } catch (err) {
//   //         setError(err.message);
//   //       } finally {
//   //         setLoading(false);
//   //       }
//   //     };

//   //     fetchDoctors();
//   //   }, []);

//   const renderStars = (rating) => {
//     const stars = [];
//     for (let i = 0; i < 5; i++) {
//       stars.push(
//         <span key={i} className={i < rating ? "text-warning" : "text-muted"}>
//           ★
//         </span>
//       );
//     }
//     return stars;
//   };

//   const handleLoginSuccess = () => {
//     setIsAuthenticated(true);
//     // Optionally redirect to a different page or show a success message
//   };
//   const showBackButton = location.key !== "default";
//   return (
//     <>
//       <Navbar
//         navDark={true}
//         manuClass="navigation-menu nav-left"
//         containerClass="container"
//       />

//       <div className="container mt-4">
//         <div className="row">

//           <div className="col-md-8">
//             {loading ? (
//               <SkeletonLoader />
//             ) : error ? (
//               <div className="text-center">
//                 <p className="text-muted">Error: {error}</p>
//               </div>
//             ) : doctors.length > 0 ? (
//               doctors.map((doctor) => (
//                 <div className="card mb-3" key={doctor.user}>
//                   <div className="card-body">
//                     <div className="row align-items-center">
//                       <div className="col-auto">
//                         <img
//                           src={doctor.image || dr1} // Replace with your default image path
//                           alt={`${doctor.first_name} ${doctor.last_name}`}
//                           style={{
//                             width: "100px",
//                             height: "100px",
//                             borderRadius: "50%",
//                           }}
//                         />
//                       </div>
//                       <div className="col">
//                         <h5 className="card-title">
//                           {doctor.first_name} {doctor.last_name}
//                         </h5>
//                         <p className="card-text">
//                           Specialties:{" "}
//                           {Array.isArray(doctor.specialities)
//                             ? doctor.specialities.join(", ")
//                             : doctor.specialities}
//                         </p>
//                         <div className="d-flex align-items-center">
//                           <ul className="list-unstyled mb-0 me-2">
//                             {renderStars(doctor.average_rating)}
//                           </ul>
//                           <span className="text-muted">
//                             {parseFloat(doctor.average_rating).toFixed(2)} Stars
//                           </span>
//                         </div>
//                         <p>
//                           <FaUserMd /> {doctor.years_of_experience} Years
//                           Experience
//                         </p>
//                         <p>
//                           <RiMapPinLine /> {doctor.location}
//                         </p>
//                         <p>
//                           <FaSchool /> {doctor.medical_school}
//                         </p>
//                         <p>
//                           <FaAddressCard /> {doctor.street_address}
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ))
//             ) : (
//               <div className="text-center">No doctors found.</div>
//             )}
//           </div>

//           <div className="col-md-4">
//             <div className="card">
//               <div className="card-body">
//                 <h5 className="card-title">Login to Book an Appointment</h5>
//                 <MobileLogin handleLoginSuccess={handleLoginSuccess} />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AppointmentPage;


// src/pages/AppointmentPage.js


// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import { FaUserMd, FaSchool, FaAddressCard } from "react-icons/fa";
// import { RiMapPinLine } from "react-icons/ri"; 
// import MobileLogin from "../../components/CommonComponents/MobileLogin";
// import SkeletonLoader from "./SkeletonLoader";
// import dr1 from "../../assets/images/doctors/Avatar.jpg";
// import Navbar from "../../components/navbar";
// import Footer from "../../components/footer";
// import ScrollTop from "../../components/scrollTop";


// const AppointmentPage = () => {
//   const [doctors, setDoctors] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const { id } = useParams();
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchDoctors = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/doctors/details/${id}/`
//         );

//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }

//         const data = await response.json();
//         setDoctors(Array.isArray(data) ? data : [data]);
//       } catch (err) {
//         setError(err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchDoctors();
//   }, [id]);

//   const renderStars = (rating) => {
//     return Array.from({ length: 5 }, (_, i) => (
//       <span key={i} className={i < rating ? "text-warning" : "text-muted"}>
//         ★
//       </span>
//     ));
//   };

//   const handleLoginSuccess = () => {
//     setIsAuthenticated(true);
//   };

//   return (
//     <>
//       <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container" />

//       <section className="section" style={{ marginTop: "20px", paddingTop: "30px" }}>
//         <div className="container">
//           <div className="row">
//             <div className="col-md-8">
//               {loading ? (
//                 <SkeletonLoader />
//               ) : error ? (
//                 <div className="text-center">
//                   <p className="text-muted">Error: {error}</p>
//                 </div>
//               ) : doctors.length > 0 ? (
//                 doctors.map((doctor) => (
//                   <div className="col-12 p-0 mb-3" key={doctor.user}>
//                     <div className="card team border-0 rounded shadow overflow-hidden">
//                       <div className="team-person" style={{ flexShrink: 0 }}>
//                         <img
//                           src={doctor.image || dr1}
//                           alt={`${doctor.first_name} ${doctor.last_name}`}
//                           style={{
//                             width: "100px",
//                             height: "100px",
//                             borderRadius: "50%",
//                           }}
//                         />
//                       </div>
//                       <div className="card-body" style={{ padding: "20px" }}>
//                         <h5 className="title text-dark h5 mb-0">
//                           {doctor.first_name} {doctor.last_name}
//                         </h5>
//                         <p className="card-text">
//                           Specialties:{" "}
//                           {Array.isArray(doctor.specialities)
//                             ? doctor.specialities.join(", ")
//                             : doctor.specialities}
//                         </p>
//                         <div className="d-flex align-items-center">
//                           <ul className="list-unstyled mb-0 me-2">
//                             {renderStars(doctor.average_rating)}
//                           </ul>
//                           <p className="text-muted mb-0">
//                             {parseFloat(doctor.average_rating).toFixed(2)} Stars
//                           </p>
//                         </div>
//                         <p>
//                           <FaUserMd className="text-primary me-2" />
//                           {doctor.years_of_experience} Years Experience
//                         </p>
//                         <p>
//                           <RiMapPinLine className="text-primary me-2" />
//                           {doctor.location}
//                         </p>
//                         <p>
//                           <FaSchool className="text-primary me-2" />
//                           {doctor.medical_school}
//                         </p>
//                         <p>
//                           <FaAddressCard className="text-primary me-2" />
//                           {doctor.street_address}
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 ))
//               ) : (
//                 <div className="text-center">No doctors found.</div>
//               )}
//             </div>

//             <div className="col-md-4">
//               <div className="card">
//                 <div className="card-body">
//                   <h5 className="card-title">Login to Book an Appointment</h5>
//                   <MobileLogin handleLoginSuccess={handleLoginSuccess} />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       <Footer />
//       <ScrollTop />
//     </>
//   );
// };

// export default AppointmentPage;



// import React, { useEffect, useState } from "react";
// import { FaUserMd, FaSchool, FaAddressCard } from "react-icons/fa";
// import { RiMapPinLine } from "react-icons/ri"; 
// import AppointmentLogin from "../../components/CommonComponents/AppointmentLogin";
// import SkeletonLoader from "./SkeletonLoader";
// import dr1 from "../../assets/images/doctors/Avatar.jpg";
// import Navbar from "../../components/navbar";
// import Footer from "../../components/footer";
// import ScrollTop from "../../components/scrollTop";
// import { useLocation, useNavigate, useParams } from "react-router-dom";

// const AppointmentPage = () => {
//   const [doctors, setDoctors] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const location = useLocation();
//   const bookingType = location.state?.bookingType;
//   useEffect(() => {
//     const fetchDoctors = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/doctors/details/${id}/`
//         );

//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }

//         const data = await response.json();
//         setDoctors(Array.isArray(data) ? data : [data]);
//       } catch (err) {
//         setError(err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchDoctors();
//   }, [id]);

//   const renderStars = (rating) => {
//     return Array.from({ length: 5 }, (_, i) => (
//       <span key={i} className={i < rating ? "text-warning" : "text-muted"}>
//         ★
//       </span>
//     ));
//   };

//   const handleLoginSuccess = () => {
//     setIsAuthenticated(true);
//   };

//   return (
//     <>
//       <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container" />

//       <section className="section" style={{ marginTop: "20px", paddingTop: "30px" }}>
//         <div className="container">
//           <div className="row">
//             <div className="col-md-8">
//               {loading ? (
//                 <SkeletonLoader />
//               ) : error ? (
//                 <div className="text-center">
//                   <p className="text-muted">Error: {error}</p>
//                 </div>
//               ) : doctors.length > 0 ? (
//                 doctors.map((doctor) => (
//                     <div className="col-12 p-0 mb-3" key={doctor.user}>
//                     <div className="card team border-0 rounded shadow overflow-hidden" style={{ minHeight: "400px", padding: "20px" }}>

//                       <div className="d-flex">
//   <div className="team-person" style={{ flexShrink: 0, marginRight: "20px" }}>
//     <img
//       src={doctor.image || dr1}
//       alt={`${doctor.first_name} ${doctor.last_name}`}
//       style={{
//         width: "120px",
//         height: "120px",
//         borderRadius: "50%",
//       }}
//     />
//   </div>
//   <div className="card-body" style={{ padding: "20px", flex: 1 }}>
//     <div className="d-flex justify-content-between align-items-center">
//       <h5 className="title text-dark h5 mb-0">
//         {doctor.first_name} {doctor.last_name}
//       </h5>
//       <div className="mt-2">
//         <AppointmentLogin handleLoginSuccess={handleLoginSuccess} />
//       </div>
//     </div>
//     <p className="card-text">
//       Specialties:{" "}
//       {Array.isArray(doctor.specialities)
//         ? doctor.specialities.join(", ")
//         : doctor.specialities}
//     </p>
//     <div className="d-flex align-items-center">
//       <ul className="list-unstyled mb-0 me-2">
//         {renderStars(doctor.average_rating)}
//       </ul>
//       <p className="text-muted mb-0">
//         {parseFloat(doctor.average_rating).toFixed(2)} Stars
//       </p>
//     </div>
//     <p>
//       <FaUserMd className="text-primary me-2" />
//       {doctor.years_of_experience} Years Experience
//     </p>
//     <p>
//       <RiMapPinLine className="text-primary me-2" />
//       {doctor.location}
//     </p>
//     <p>
//       <FaSchool className="text-primary me-2" />
//       {doctor.medical_school}
//     </p>
//     <p>
//       <FaAddressCard className="text-primary me-2" />
//       {doctor.street_address}
//     </p>
//   </div>
// </div>


//                     </div>
//                   </div>

//                 ))
//               ) : (
//                 <div className="text-center">No doctors found.</div>
//               )}
//             </div>

//             <div className="col-md-4">
//             </div>
//           </div>
//         </div>
//       </section>

//       <Footer />
//       <ScrollTop />
//     </>
//   );
// };

// export default AppointmentPage;


// import React, { useEffect, useState } from "react";
// import { FaUserMd, FaSchool, FaAddressCard } from "react-icons/fa";
// import { RiMapPinLine } from "react-icons/ri"; 
// import AppointmentLogin from "../../components/CommonComponents/AppointmentLogin";
// import SkeletonLoader from "./SkeletonLoader";
// import dr1 from "../../assets/images/doctors/Avatar.jpg";
// import Navbar from "../../components/navbar";
// import Footer from "../../components/footer";
// import ScrollTop from "../../components/scrollTop";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import BookingAppointment from "../patient/booking-appointment";

// const AppointmentPage = () => {
//   const [doctors, setDoctors] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const location = useLocation();
//   const [modalShow, setModalShow] = useState(false); // State for modal visibility
//   const [selectedDoctor, setSelectedDoctor] = useState(location.state?.selectedDoctor); // State for selected doctor
//   const { id } = useParams();
//   const navigate = useNavigate();

//   const bookingType = location.state?.bookingType; // Get booking type from location state
// //   const selectedDoctor = location.state?.selectedDoctor; 
//   useEffect(() => {
//     const fetchDoctors = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/doctors/details/${id}/`
//         );

//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }

//         const data = await response.json();
//         setDoctors(Array.isArray(data) ? data : [data]);
//       } catch (err) {
//         setError(err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchDoctors();
//   }, [id]);

//   const renderStars = (rating) => {
//     return Array.from({ length: 5 }, (_, i) => (
//       <span key={i} className={i < rating ? "text-warning" : "text-muted"}>★</span>
//     ));
//   };

// //   const handleLoginSuccess = () => {
// //     setIsAuthenticated(true);
// //     setModalShow(true); // Open the booking modal on successful login
// //   };
// const handleLoginSuccess = () => {
//     setIsAuthenticated(true);
//     setModalShow(true); // Open the booking modal on successful login
//     // Resetting the selectedDoctor for the modal
//     setSelectedDoctor(selectedDoctor); // Pass selectedDoctor from location state
//   };

//   // ...




//   return (
//     <>
//       <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container" />

//       <section className="section" style={{ marginTop: "20px", paddingTop: "30px" }}>
//         <div className="container">
//           <div className="row">
//             <div className="col-md-8">
//               {loading ? (
//                 <SkeletonLoader />
//               ) : error ? (
//                 <div className="text-center">
//                   <p className="text-muted">Error: {error}</p>
//                 </div>
//               ) : doctors.length > 0 ? (
//                 doctors.map((doctor) => (
//                   <div className="col-12 p-0 mb-3" key={doctor.user}>
//                     <div className="card team border-0 rounded shadow overflow-hidden" style={{ minHeight: "400px", padding: "20px" }}>
//                       <div className="d-flex">
//                         <div className="team-person" style={{ flexShrink: 0, marginRight: "20px" }}>
//                           <img
//                             src={doctor.image || dr1}
//                             alt={`${doctor.first_name} ${doctor.last_name}`}
//                             style={{
//                               width: "120px",
//                               height: "120px",
//                               borderRadius: "50%",
//                             }}
//                           />
//                         </div>
//                         <div className="card-body" style={{ padding: "20px", flex: 1 }}>
//                           <div className="d-flex justify-content-between align-items-center">
//                             <h5 className="title text-dark h5 mb-0">
//                               {doctor.first_name} {doctor.last_name}
//                             </h5>
//                             <div className="mt-2">
//                             <AppointmentLogin 
//                                 onLoginSuccess={handleLoginSuccess} 
//                                 bookingType={bookingType} // Pass booking type here
//                               />
//                               {/* <AppointmentLogin handleLoginSuccess={() => handleLoginSuccess(doctor)} /> */}
//                             </div>
//                           </div>
//                           <p className="card-text">
//                             Specialties:{" "}
//                             {Array.isArray(doctor.specialities)
//                               ? doctor.specialities.join(", ")
//                               : doctor.specialities}
//                           </p>
//                           <div className="d-flex align-items-center">
//                             <ul className="list-unstyled mb-0 me-2">
//                               {renderStars(doctor.average_rating)}
//                             </ul>
//                             <p className="text-muted mb-0">
//                               {parseFloat(doctor.average_rating).toFixed(2)} Stars
//                             </p>
//                           </div>
//                           <p>
//                             <FaUserMd className="text-primary me-2" />
//                             {doctor.years_of_experience} Years Experience
//                           </p>
//                           <p>
//                             <RiMapPinLine className="text-primary me-2" />
//                             {doctor.location}
//                           </p>
//                           <p>
//                             <FaSchool className="text-primary me-2" />
//                             {doctor.medical_school}
//                           </p>
//                           <p>
//                             <FaAddressCard className="text-primary me-2" />
//                             {doctor.street_address}
//                           </p>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 ))
//               ) : (
//                 <div className="text-center">No doctors found.</div>
//               )}
//             </div>

//             <div className="col-md-4">
//               {/* Other content can go here if needed */}
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Booking Appointment Modal */}
//       <BookingAppointment
//         show={modalShow}
//         handleClose={() => setModalShow(false)}
//         selectedDoctor={selectedDoctor}
//         bookingType={bookingType}
//       />

//       <Footer />
//       <ScrollTop />
//     </>
//   );
// };

// export default AppointmentPage;


import React, { useEffect, useState } from "react";
import { FaUserMd, FaSchool, FaAddressCard } from "react-icons/fa";
import { RiMapPinLine } from "react-icons/ri";
import AppointmentLogin from "../../components/CommonComponents/AppointmentLogin";
import SkeletonLoader from "./SkeletonLoader";
import dr1 from "../../assets/images/doctors/Avatar.jpg";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BookingAppointment from "../patient/booking-appointment";
import { FaArrowLeft, FaMobile } from "react-icons/fa6";

const AppointmentPage = () => {
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();
  const [modalShow, setModalShow] = useState(false); // State for modal visibility
  const [showLogin, setShowLogin] = useState(true); // State to manage AppointmentLogin visibility
  const [selectedDoctor, setSelectedDoctor] = useState(location.state?.selectedDoctor); // State for selected doctor
  const { id } = useParams();
  const navigate = useNavigate();

  const bookingType = location.state?.bookingType; // Get booking type from location state

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/doctors/details/${id}/`
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setDoctors(Array.isArray(data) ? data : [data]);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDoctors();
  }, [id]);

  const renderStars = (rating) => {
    return Array.from({ length: 5 }, (_, i) => (
      <span key={i} className={i < rating ? "text-warning" : "text-muted"}>★</span>
    ));
  };

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
    setShowLogin(false); // Hide the AppointmentLogin component
    setModalShow(true); // Open the booking modal on successful login
  };

  const handleModalClose = () => {
    setModalShow(false);
    // Keep the login component hidden even after closing the modal
  };

  return (
    <>
      <Navbar navDark={false} manuClass="navigation-menu nav-left" containerClass="container" />

      <div
        style={{
          background: "linear-gradient(to bottom, #1e3949 50%, white 50%)",
          // height: "80vh",

        }}
        className="container-fluid rounded border">
        <div className="row">
          <div className="col-12">
            {loading ? (
              <SkeletonLoader />
            ) : error ? (
              <div className="text-center">
                <p className="text-muted">Error: {error}</p>
              </div>
            ) : doctors.length > 0 ? (
              doctors.map((doctor) => (
                <div className="col-12 mx-auto" style={{ paddingTop: "5rem", width: "900px" }} key={doctor.user}>
                  <div className="card team border-0 p-2 mb-2 rounded shadow overflow-hidden" style={{ minHeight: "400px" }}>
                    <div className='row' style={{ margin: "auto 0px" }}>
                      <div className="col-12 col-lg-6">
                        <div className='p-4'>
                          <div className="py-2">
                            <h6 style={{ fontWeight: '800', cursor: 'pointer' }}  className="mb-0">
                              <FaArrowLeft onClick={() => navigate(-1)} size={30} className="text-primary me-2" />
                              Appointment Details
                            </h6>
                          </div>
                          <div className="team-person">
                            <p>
                              <FaUserMd className="text-primary me-2" />
                              {doctor.years_of_experience} Years Experience
                            </p>
                            <p>
                              <RiMapPinLine className="text-primary me-2" />
                              {doctor.location}
                            </p>
                            <p>
                              <FaSchool className="text-primary me-2" />
                              {doctor.medical_school}
                            </p>
                            <p>
                              <FaAddressCard className="text-primary me-2" />
                              {doctor.street_address}
                            </p>
                          </div>
                          <div className='d-flex align-items-center gap-2'>
                            <img
                              src={doctor.image || dr1}
                              alt={`${doctor.first_name} ${doctor.last_name}`}
                              className='rounded img-thumbnail '
                              style={{
                                width: "100px",
                                height: "100px"
                              }}
                            />
                            <div>
                              <h5 className="title text-dark h5 mb-0">
                                {doctor.first_name} {doctor.last_name}
                              </h5>
                              <p className="card-text">
                                Specialties:{" "}
                                {Array.isArray(doctor.specialities)
                                  ? doctor.specialities.join(", ")
                                  : doctor.specialities}
                              </p>
                              <div className="d-flex align-items-center">
                                <ul className="list-unstyled mb-0 me-2">
                                  {renderStars(doctor.average_rating)}
                                </ul>
                                <p className="text-muted mb-0">
                                  {parseFloat(doctor.average_rating).toFixed(2)} Stars
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="pb-1 px-2 pt-4" style={{ borderBottom: "3px solid #1e3949" }}>
                          <h6>
                            <FaMobile size={30} className="text-primary me-2" />
                            Enter Mobile Number
                          </h6>
                        </div>
                        <div style={{ marginTop: "2rem" }}>
                          <div className="">
                            <div className="mt-2">
                              {showLogin && (
                                <AppointmentLogin
                                  onLoginSuccess={handleLoginSuccess}
                                  bookingType={bookingType} // Pass booking type here
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center">No doctors found.</div>
            )}
          </div>

          {/* <div className="col-md-4">
            Other content can go here if needed
          </div> */}
        </div>
      </div >

      {/* Booking Appointment Modal */}
      < BookingAppointment
        show={modalShow}
        handleClose={handleModalClose}
        selectedDoctor={selectedDoctor}
        bookingType={bookingType}
      />

      <Footer />
      <ScrollTop />
    </>
  );
};

export default AppointmentPage;