// import { useState } from 'react';
// import { Link } from "react-router-dom";

// import logoDark from "../../assets/images/logo-dark.png"

// import Modal from 'react-bootstrap/Modal';

// export default function InvoiceView(){
//     const [show, setShow] = useState(false);
//     return(
//         <>
//         <Link to="#" className="btn btn-sm btn-primary" onClick={()=>setShow(!show)}>View</Link>

//         <Modal show={show} size="lg" centered onHide={()=>setShow(!show)}>
//             <Modal.Header closeButton>
//             <Modal.Title className='h5'>Patient Invoice</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//                 <div className="modal-body p-3 pt-4">
//                     <div className="row mb-4">
//                         <div className="col-lg-8 col-md-6">
//                             <img src={logoDark} height="22" alt=""/>
//                             <h6 className="mt-4 pt-2">Address :</h6>
//                             <small className="text-muted mb-0">1419 Riverwood Drive, <br/>Redding, CA 96001</small>
//                         </div>

//                         <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
//                             <ul className="list-unstyled">
//                                 <li className="d-flex">
//                                     <small className="mb-0 text-muted">Invoice no. : </small>
//                                     <small className="mb-0 text-dark">&nbsp;&nbsp;#54638990jnn</small>
//                                 </li>
//                                 <li className="d-flex mt-2">
//                                     <small className="mb-0 text-muted">Email : </small>
//                                     <small className="mb-0">&nbsp;&nbsp;<Link to="mailto:contact@example.com" className="text-dark">info@doctris.com</Link></small>
//                                 </li>
//                                 <li className="d-flex mt-2">
//                                     <small className="mb-0 text-muted">Phone : </small>
//                                     <small className="mb-0">&nbsp;&nbsp;<Link to="tel:+152534-468-854" className="text-dark">(+12) 1546-456-856</Link></small>
//                                 </li>
//                                 <li className="d-flex mt-2">
//                                     <small className="mb-0 text-muted">Website : </small>
//                                     <small className="mb-0">&nbsp;&nbsp;<Link to="#" className="text-dark">www.doctris.com</Link></small>
//                                 </li>
//                                 <li className="d-flex mt-2">
//                                     <small className="mb-0 text-muted">Patient Name : </small>
//                                     <small className="mb-0">&nbsp;&nbsp;Mary Skeens</small>
//                                 </li>
//                             </ul>
//                         </div>
//                     </div>

//                     <div className="pt-4 border-top">
//                         <div className="row">
//                             <div className="col-lg-8 col-md-6">
//                                 <h5 className="text-muted fw-bold">Invoice <span className="badge rounded-pill bg-soft-success fw-normal ms-2">Paid</span></h5>
//                                 <h6>Surgery (Gynecology)</h6>
//                             </div>

//                             <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
//                                 <ul className="list-unstyled">
//                                     <li className="d-flex mt-2">
//                                         <small className="mb-0 text-muted">Issue Dt. : </small>
//                                         <small className="mb-0 text-dark">&nbsp;&nbsp;25th Sep. 2020</small>
//                                     </li>

//                                     <li className="d-flex mt-2">
//                                         <small className="mb-0 text-muted">Due Dt. : </small>
//                                         <small className="mb-0 text-dark">&nbsp;&nbsp;11th Oct. 2020</small>
//                                     </li>

//                                     <li className="d-flex mt-2">
//                                         <small className="mb-0 text-muted">Dr. Name : </small>
//                                         <small className="mb-0 text-dark">&nbsp;&nbsp;Dr. Calvin Carlo</small>
//                                     </li>
//                                 </ul>
//                             </div>
//                         </div>

//                         <div className="invoice-table pb-4">
//                             <div className="table-responsive shadow rounded mt-4">
//                                 <table className="table table-center invoice-tb mb-0">
//                                     <thead>
//                                         <tr>
//                                             <th scope="col" className="text-start border-bottom p-3" style={{minWidth:'60px'}}>No.</th>
//                                             <th scope="col" className="text-start border-bottom p-3" style={{minWidth:'220px'}}>Item</th>
//                                             <th scope="col" className="text-center border-bottom p-3" style={{minWidth:'60px'}}>Qty</th>
//                                             <th scope="col" className="border-bottom p-3" style={{minWidth:'130px'}}>Rate</th>
//                                             <th scope="col" className="border-bottom p-3" style={{minWidth:'130px'}}>Total</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         <tr>
//                                             <th scope="row" className="text-start p-3">1</th>
//                                             <td className="text-start p-3">Hospital Charges</td>
//                                             <td className="text-center p-3">1</td>
//                                             <td className="p-3">$ 125</td>
//                                             <td className="p-3">$ 125</td>
//                                         </tr>
//                                         <tr>
//                                             <th scope="row" className="text-start p-3">2</th>
//                                             <td className="text-start p-3">Medicine</td>
//                                             <td className="text-center p-3">1</td>
//                                             <td className="p-3">$ 245</td>
//                                             <td className="p-3">$ 245</td>
//                                         </tr>
//                                         <tr>
//                                             <th scope="row" className="text-start p-3">3</th>
//                                             <td className="text-start p-3">Special Visit Fee(Doctor)</td>
//                                             <td className="text-center p-3">1</td>
//                                             <td className="p-3">$ 150</td>
//                                             <td className="p-3">$ 150</td>
//                                         </tr>
//                                     </tbody>
//                                 </table>
//                             </div>

//                             <div className="row">
//                                 <div className="col-lg-4 col-md-5 ms-auto">
//                                     <ul className="list-unstyled h6 fw-normal mt-4 mb-0 ms-md-5 ms-lg-4">
//                                         <li className="text-muted d-flex justify-content-between pe-3">Subtotal :<span>$ 520</span></li>
//                                         <li className="text-muted d-flex justify-content-between pe-3">Taxes :<span> 0</span></li>
//                                         <li className="d-flex justify-content-between pe-3">Total :<span>$ 520</span></li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="border-top pt-4">
//                             <div className="row">
//                                 <div className="col-sm-6">
//                                     <div className="text-sm-start text-muted text-center">
//                                         <small className="mb-0">Customer Services : <Link to="tel:+152534-468-854" className="text-warning">(+12) 1546-456-856</Link></small>
//                                     </div>
//                                 </div>

//                                 <div className="col-sm-6">
//                                     <div className="text-sm-end text-muted text-center">
//                                         <small className="mb-0"><Link to="#" className="text-primary">Terms & Conditions</Link></small>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </Modal.Body>

//         </Modal>
//         </>
//     )
// }


import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import logo from "../../assets/images/healthBridge_transparent_logo.png";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas"; // To capture HTML as a canvas
import { ImageWithFallback } from "../CommonComponents/CommonFunction";
import dr1 from "../../assets/images/doctors/01.jpg";

export default function InvoiceView({ invoiceId }) {
  const [show, setShow] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const baseURL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("accessToken");

  const fetchInvoiceDetails = async (id) => {
    try {
      const response = await fetch(`${baseURL}/invoices/${id}/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setInvoiceDetails(data);
    } catch (error) {
      console.error("Error fetching invoice details:", error);
    }
  };

  const handleShow = () => {
    setShow(true);
    fetchInvoiceDetails(invoiceId);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return ""; 
    return date.toLocaleDateString('en-GB'); 
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return ""; 
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const handlePrint = async () => {
    const input = document.getElementById("invoice-modal-content");
    
    // Use html2canvas to take a snapshot of the HTML
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL('image/png');

    const pdf = new jsPDF();
    
    // Add the image to the PDF
    pdf.addImage(imgData, 'PNG', 0, 0);
    
    // Save the PDF
    pdf.save(`invoice_${invoiceDetails.invoice_id}.pdf`);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const imageStyle = {
    width: windowWidth < 768 ? "40px" : "45px",
    height: windowWidth < 768 ? "40px" : "45px",
    borderRadius: "50%",
    objectFit: "cover",
  };
  return (
    <>
      <Link to="#" className="btn btn-sm btn-primary" onClick={handleShow}>
        View
      </Link>

      <Modal show={show} size="lg" centered onHide={() => setShow(false)}>
      <Modal.Header>
  <Modal.Title className="h5 mb-0">Patient Invoice</Modal.Title>
  <div className="ms-auto d-flex align-items-center">
    <button className="btn btn-sm btn-primary me-2" onClick={handlePrint}>
      Print
    </button>
    <button type="button" className="btn-close" aria-label="Close" onClick={() => setShow(false)} />
  </div>
</Modal.Header>

        {/* <Modal.Header closeButton>
     
          <Modal.Title className="h5">Patient Invoice</Modal.Title>
          <button className="btn btn-sm btn-primary me-2" onClick={handlePrint}>
            Print
          </button>
        </Modal.Header> */}
      
        <Modal.Body id="invoice-modal-content">
          {invoiceDetails ? (
            <div className="modal-body p-4">
              <div className="row mb-4">
                <div className="col-lg-8 col-md-6" style={{ position: 'relative' }}>
                <ImageWithFallback
                        src={ dr1}
                        defaultSrc={dr1}
                        // alt={selectedDoctor.first_name + " " + selectedDoctor.last_name}
                        style={imageStyle}
                      />
                  {/* <img src={logo} height="22" alt="healthBridge" /> */}
                  <h6 className="mt-4 pt-2">Address :</h6>
                  <small className="text-muted mb-0">{invoiceDetails.doctor_info?.doctor_address || "N/A"}</small>
                  <h6 className="mt-4">Doctor Information</h6>
                  <ul className="list-unstyled" style={{ lineHeight: '1.5' }}>
                    <li>
                      <small className="mb-0 text-muted"><strong>Name:</strong></small>
                      {` ${invoiceDetails.doctor_info?.doctor_first_name} ${invoiceDetails.doctor_info?.doctor_last_name}`}
                    </li>
                    {invoiceDetails.clinic_details && (
                      <>
                        <li>
                          <small className="mb-0 text-muted"><strong>Clinic Name:</strong></small>
                          {` ${invoiceDetails.clinic_details.clinic_name}`}
                        </li>
                        <li>
                          <small className="mb-0 text-muted"><strong>Clinic Address:</strong></small>
                          {` ${invoiceDetails.clinic_details.clinic_address}`}
                        </li>
                      </>
                    )}
                    <li>
                      <small className="mb-0 text-muted"><strong>Email:</strong></small>
                      <Link to={`mailto:${invoiceDetails.doctor_info?.doctor_email || "#"}`} className="text-dark">
                        {` ${invoiceDetails.doctor_info?.doctor_email || "N/A"}`}
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <ul className="list-unstyled" style={{ lineHeight: '1.5' }}>
                    <li className="d-flex">
                      <small className="mb-0 text-muted"><strong>Invoice no. :</strong></small>
                      <small className="mb-0 text-dark">{` #${invoiceDetails.invoice_id || "N/A"}`}</small>
                    </li>
                    <li className="d-flex mt-2">
                      <small className="mb-0 text-muted"><strong>Patient Name:</strong></small>
                      <small className="mb-0">{` ${invoiceDetails.patient_info?.patient_name || "N/A"}`}</small>
                    </li>
                    <li className="d-flex mt-2">
                      <small className="mb-0 text-muted"><strong>Email:</strong></small>
                      <small className="mb-0">
                        <Link to={`mailto:${invoiceDetails.patient_info?.patient_email || "#"}`} className="text-dark">
                          {` ${invoiceDetails.patient_info?.patient_email || "N/A"}`}
                        </Link>
                      </small>
                    </li>
                    <li className="d-flex mt-2">
                      <small className="mb-0 text-muted"><strong>Appointment Date:</strong></small>
                      <small className="mb-0">{` ${formatDate(invoiceDetails.appointment_info?.appointment_date)}`}</small>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="pt-4 border-top">
                <div className="row">
                  <div className="col-lg-8 col-md-6">
                    <h5 className="text-muted fw-bold">
                      Invoice{" "}
                      <span className="badge rounded-pill bg-soft-success fw-normal ms-2">
  {invoiceDetails.payment_info?.payment_status === 'successful' ? 'Paid' : 'N/A'}
</span>

                      {/* <span className="badge rounded-pill bg-soft-success fw-normal ms-2">
                        {invoiceDetails.payment_info?.payment_status || "N/A"}
                      </span> */}
                    </h5>
                    <h6>Booking Type: {invoiceDetails.appointment_info?.appointment_type || "N/A"}</h6>
                  </div>

                  <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <ul className="list-unstyled">
                      <li className="d-flex mt-2">
                        <small className="mb-0 text-muted">Generated Date:</small>
                        <small className="mb-0 text-dark">{` ${formatDate(invoiceDetails.invoice_generated_date)}`}</small>
                      </li>
                      <li className="d-flex mt-2">
                        <small className="mb-0 text-muted">Amount: {""}</small>
                        <small className="mb-0 text-dark">{` ₹ ${invoiceDetails.payment_info?.amount || "N/A"}`}</small>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="invoice-table pb-4">
                <div className="table-responsive shadow rounded mt-4">
                  <table className="table table-center invoice-tb mb-0">
                    <thead>
                      <tr>
                        <th scope="col" className="text-start border-bottom p-3" style={{ minWidth: '60px' }}>No.</th>
                        <th scope="col" className="text-start border-bottom p-3" style={{ minWidth: '220px' }}>Item</th>
                        <th scope="col" className="border-bottom p-3" style={{ minWidth: '130px' }}>Rate</th>
                        <th scope="col" className="border-bottom p-3" style={{ minWidth: '130px' }}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceDetails && (
                        <tr>
                          <th scope="row" className="text-start p-3">1</th>
                          <td className="text-start p-3">Doctor Charges</td>
                          <td className="p-3">₹ {invoiceDetails.payment_info?.amount || 0}</td>
                          <td className="p-3">₹ {invoiceDetails.payment_info?.amount || 0}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="row">
                  <div className="col-lg-4 col-md-5 ms-auto">
                    <ul className="list-unstyled h6 fw-normal mt-4 mb-0 ms-md-5 ms-lg-4">
                      <li className="text-muted d-flex justify-content-between pe-3">Subtotal: <span>₹ {invoiceDetails.payment_info?.amount || 0}</span></li>
                      <li className="text-muted d-flex justify-content-between pe-3">Taxes: <span>₹ 0</span></li>
                      <li className="d-flex justify-content-between pe-3">Total: <span>₹ {invoiceDetails.payment_info?.amount || 0}</span></li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          ) : (
            <p>Loading...</p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
