import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import ViewHealthMetricChart from "./ViewHealthMetricChart";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import PrescriptionModal from "./PrescriptionModal"; // Import the modal component
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Spinner,
  Form,
} from "react-bootstrap";
import { Table } from "reactstrap";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import Header from "../../components/CommonComponents/Header";
import jsPDF from "jspdf"; // PDF library
import "jspdf-autotable"; // Plugin for tables in PDF
// import Spinner from "react-bootstrap/Spinner"; // Import Spinner if needed
import { ShowSucess, ShowError } from "../../_helper/helper";
import { TextField, Box, Typography, Grid, CardContent } from "@mui/material";
import { loadingPanel } from "../../components/CommonComponents/CommonFunction";
import { get, post } from "../../api_helper";
const baseURL = process.env.REACT_APP_API_URL;

const HealthMetrics = () => {
  // if (!show) return null;
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [healthMetricsList, setHealthMetricsList] = useState(null);

  const [healthMetricInstance, setHealthMetricInstance] = useState(null);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState({
    // bp: "",
    // hr: "",
    // hb: "",
    // wt: "",
    // sg: "",
  });
  const showBackButton = location.key !== "default";
  const { patientData } = location?.state || {};
  const handleChange = (e) => {
    setRecords({ ...records, [e.target.name]: e.target.value });
  };
  const { doctorDetailsResults } = useSelector((state) => state.DoctorReducer);
  const handleOpen = () => setOpen(true);
  const downloadHealthMetricsPDF = () => {
    const data = healthMetricsList[0];
    const unit = healthMetricsList[0]?.metrics_with_lookup.map((d) => d?.unit);

    const doc = new jsPDF();

    // Set title
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 14; // Margin from the left side
    const centerX = pageWidth / 2;
    const titleX = centerX; // Center title
    const smallFontSize = 10; // Smaller font size for details
    const lineY = 20; // Y position for the line below the title
    const tableStartY = 48; // Y position to start the table

    // Title - Centered and Bold
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(16); // Larger size for title
    doc.text("Health Metrics", titleX, 16, { align: "center" });

    // Date in the top right corner of the title
    doc.setFontSize(smallFontSize);
    // doc.text(data.date_recorded.split("T")[0], pageWidth - margin, 16, {
    //   align: "right",
    // });

    // Draw separator line below the title
    doc.setLineWidth(0.5); // Line width
    doc.line(margin, lineY, pageWidth - margin, lineY); // Draw line from left to right

    // Reset font to normal and set smaller font size
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(smallFontSize);

    // Patient Name on the left (dummy patient name used for demo)
    const patientIDText = `Patient Name: ${patientData?.patient_name}`;
    doc.text(patientIDText, margin, 24);

    // Doctor Information in one column on the right (dummy doctor info used for demo)
    const doctorInfo = [
      `Doctor Name: ${doctorDetailsResults?.first_name} ${doctorDetailsResults?.last_name}`,
      `Graduation Year: ${doctorDetailsResults?.graduation_year}`,
      `Medical School: ${doctorDetailsResults?.medical_school}`,
      `Specialization: ${doctorDetailsResults?.specialization}`,
      `Experience: ${doctorDetailsResults?.years_of_experience} Year`,
      `License Number: ${doctorDetailsResults?.medical_license_number}`,
    ];

    // Ensure that all doctor info is aligned
    const doctorTextYStart = 24; // Starting Y position for the doctor info
    let currentY = doctorTextYStart;
    const maxDoctorTextWidth = Math.max(
      ...doctorInfo.map((line) => doc.getTextWidth(line))
    );

    doctorInfo.forEach((line) => {
      doc.text(line, pageWidth - margin - maxDoctorTextWidth, currentY);
      currentY += 6; // Line spacing for doctor information
    });

    // Draw a separator line between the doctor/patient info and the table
    doc.setLineWidth(0.3); // Thinner line
    doc.line(margin, currentY + 5, pageWidth - margin, currentY + 5); // Draw line below doctor/patient info

    // Prepare the data for the PDF table
    const tableData = [];

    // Extract metrics and percentage differences
    const metrics = data.metrics;
    const percentageDifference = data.percentage_difference || {};

    // Add the header row
    const tableHeader = [
      "Date Recorded",
      "Metric",
      "Value",
      "Unit",
      ...(Object.keys(percentageDifference).length > 0
        ? ["Percentage Difference"]
        : []),
    ];

    tableData.push(tableHeader);

    // Add metrics data

    Object.keys(metrics).forEach((metricKey, index) => {
      const metricValue = metrics[metricKey];
      const percentageDiff =
        percentageDifference[metricKey] !== undefined
          ? `${percentageDifference[metricKey]}%`
          : "";

      tableData.push([
        dayjs(data.date_recorded).format("MM-DD-YYYY"), // Date only
        metricKey.replace(/_/g, " ").toUpperCase(),
        metricValue,
        unit[index],
        ...(percentageDiff ? [percentageDiff] : []),
      ]);
    });

    // Add table to the PDF
    doc.autoTable({
      head: [
        tableHeader.map((header) => ({
          content: header,
          styles: {
            fillColor: [220, 220, 220],
            textColor: [0, 0, 0],
            fontStyle: "bold",
          },
        })),
      ],
      body: tableData.slice(1), // Exclude header row from body
      startY: currentY + 10, // Adjust starting Y position to be below the separator line
      styles: {
        fillColor: [255, 255, 255], // Set the default background to white for rows
        textColor: [0, 0, 0], // Set text color to black
        lineWidth: 0.2, // Border line width
        lineColor: [0, 0, 0], // Border line color
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Light gray background for alternate rows
      },
    });

    // Save the PDF
    doc.save("HealthMetrics.pdf");
  };
  const resetForm = () => {
    setRecords({
      bp: "",
      hr: "",
      hb: "",
      wt: "",
      sg: "",
    });
  };

  const healthMetricsElements = async () => {
    try {
      setLoading(true);
      const resp = await get(`${baseURL}/health-metrics/lookup/show/`);
      setHealthMetricInstance(resp);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setHealthMetricInstance(null);
    }
  };
  useEffect(() => {
    healthMetricsElements();
  }, []);
  const healthMetricsByempId = async () => {
    try {
      setLoading(true);
      const resp = await get(
        `${baseURL}/health-metrics/list/?patient_id=${Number(
          patientData?.patient
        )}`
      );
      setHealthMetricsList(resp);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setHealthMetricsList(null);
    }
  };
  useEffect(() => {
    if (patientData?.patient) {
      healthMetricsByempId();
    }
  }, [patientData?.patient]);
  const handleSubmit = async () => {
    const doctorId = patientData?.doctor;
    const patientId = patientData?.patient; // Assuming patient ID is used as patient ID

    const areAllObjectsFilled = () => {
      if (Object.keys(records)?.length > 0) {
        return Object.keys(records).every((key) => {
          const value = records[key];
          // Check if the value is neither undefined, null, nor an empty string
          return value !== undefined && value !== null && value !== "";
        });
      } else {
        return false;
      }
    };

    const payload = {
      doctor: doctorId,
      patient: patientId,
      metrics: {
        bp: Number(records?.bp),
        sg: Number(records?.sg),
        hb: Number(records?.hb),
        hr: Number(records?.hr),
        wt: Number(records?.wt),
      },
    };

    if (doctorId && patientId && areAllObjectsFilled()) {
      try {
        setLoading(true);
        const response = await post(
          `${process.env.REACT_APP_API_URL}/health-metrics/`,
          payload
        );
        // if (!response.ok) throw new Error("Network response was not ok");
        if (response?.data?.id) {
          toast(`Created successfully`, {
            theme: "light",
            position: "top-right",
          });
          healthMetricsByempId();
          setLoading(false);
          resetForm();
        } else {
          ShowError("Something Went Wrong!");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error creating health metrics:", error);
        ShowError("Error creating health metrics");
        setLoading(false);
      } finally {
        setLoading(false); // Reset loading action
      }
    } else {
      return;
    }
  };
  return (
    <>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container-fluid"
      />
      {/* <Container> */}
      <section className="bg-dashboard">
        <div className="container-fluid">
          <div className="row">
            <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
            <div className="col-xl-9 col-lg-8 col-md-7 mt-4 mt-sm-0 pt-sm-0">
              <Header
                title="Health Metrics" // Replace with the title you want
                onBackClick={() => navigate(-1)}
                showBackButton={showBackButton}
                headerEnd={
                  patientData && Object.keys(patientData)?.length > 0
                    ? `${patientData?.patient_name} /${
                        patientData?.age
                      } year old /${
                        patientData?.gender === "M"
                          ? "Male"
                          : patientData?.gender === "F"
                          ? "Female"
                          : patientData?.gender
                      }`
                    : null
                }
              />
              <Card>
                {loading && loadingPanel()}

                <Card.Body>
                  {/* <Row
                    className="mb-3"
                    style={{
                      textAlign: "center",
                      fontSize: "22px",
                    }}
                  >
                    {" "}
                    
                    <Card.Title>Patient Details & Prescription</Card.Title>
                  </Row> */}
                  {/* {patient && ( */}
                  <>
                    {/* <Row xs={12}>
                      <Col xs={4} className="d-flex">
                        <h6>Name:</h6>
                        <p className="text-muted ms-3">
                          {patientData?.patient_name}
                        </p>
                      </Col>
                      <Col xs={4} className="d-flex">
                        <h6>Age:</h6>
                        <p className="text-muted ms-3">
                          {patientData?.age} year old
                        </p>
                      </Col>
                      <Col xs={4} className="d-flex">
                        <h6>Gender:</h6>
                        <p className="text-muted ms-3">{patientData?.gender}</p>
                      </Col>
                    </Row>
                    <Row xs={12}>
                      <Col xs={4} className="d-flex">
                        <h6 className="mb-0">Reason:</h6>
                        <p className="text-muted ms-3 mb-0">
                          {patientData?.reason}
                        </p>
                      </Col>
                      <Col xs={4} className="d-flex">
                        <h6>Appointment Date:</h6>
                        <p className="text-muted ms-3">
                          {patientData?.appointment_date}
                        </p>
                      </Col>
                      <Col xs={4} className="d-flex">
                        <h6>Appointment Time:</h6>
                        <p className="text-muted ms-3">
                          {patientData?.appointment_time}
                        </p>
                      </Col>
                    </Row>

                    <hr /> */}
                    <Row>
                      {" "}
                      <Col xs={8}>
                        {" "}
                        <Typography variant="h4" gutterBottom>
                          Add Health Metrics
                        </Typography>
                      </Col>
                      <Col>
                        <a
                          href="#"
                          style={{
                            color: "#007bff", // Bootstrap primary color
                            textDecoration: "none",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleOpen();
                          }}
                        >
                          View Latest Measured Health Metric
                        </a>{" "}
                      </Col>
                    </Row>

                    <Grid container spacing={2}>
                      {healthMetricInstance &&
                        healthMetricInstance?.length > 0 &&
                        healthMetricInstance.map((metric) => (
                          <Grid item xs={12} md={6} key={metric.id}>
                            {/* <Card>
                              <CardContent> */}
                            <Typography variant="h6">{metric.name}</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {metric.description}
                            </Typography>
                            <TextField
                              fullWidth
                              label={`Enter ${metric.name}`}
                              variant="outlined"
                              name={metric?.code}
                              type="text"
                              error={!records[metric.code]}
                              value={records[metric.code]}
                              onChange={(e) => handleChange(e)}
                              InputProps={{
                                endAdornment: (
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {metric.unit}
                                  </Typography>
                                ),
                              }}
                              margin="normal"
                            />
                            {/* </CardContent>
                            </Card> */}
                          </Grid>
                        ))}
                    </Grid>
                    {healthMetricInstance &&
                      healthMetricInstance?.length > 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                            style={{ width: "200px", marginTop: "8px" }}
                          >
                            Submit
                          </Button>
                          <Button
                            variant="contained"
                            className="btn btn-primary"
                            onClick={resetForm}
                            style={{
                              width: "200px",
                              marginTop: "8px",
                              marginLeft: "18px",
                            }}
                          >
                            Reset
                          </Button>
                        </div>
                      )}

                    {/* <div>
                      <div
                        style={{
                          maxHeight: "500px",
                          overflowY: "auto",
                          overflowX: "hidden",
                        }}
                      >
                        <Row className="mt-2 mb-4">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <h5>Health Metrics List</h5>
                            <Button
                              style={{ marginRight: "50px" }}
                              className="btn btn-primary"
                              onClick={() => downloadPrescriptionHistory()}
                            >
                              Download Records
                            </Button>
                          </div>
                        </Row>
                        <Table size="sm" style={{ marginBottom: 0 }}>
                          <thead>
                            <tr>
                              <th>Description</th>
                              <th>Date</th>
                              <th>Details</th>
                            </tr>
                          </thead>
                          <tbody>
                            {prescriptionHistory?.map((record) => (
                              <tr key={record.id}>
                                <td>{truncateText(record.description, 90)}</td>
                                <td>{record.date}</td>
                                <td>
                                  <Button
                                    variant="link"
                                    onClick={() =>
                                      handleShowModal(record.items)
                                    }
                                  >
                                    <FaEye />
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </div> */}
                  </>
                  {/* )} */}
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>
      {healthMetricsList && healthMetricsList?.length > 0 && (
        <ViewHealthMetricChart
          open={open}
          setOpen={setOpen}
          healthMetricsList={healthMetricsList[0]}
          downloadHealthMetricsPDF={downloadHealthMetricsPDF}
        />
      )}

      {/* </Container> */}
    </>

    // <Modal show={show} onHide={onHide} size="lg">

    // </Modal>
  );
};

export default HealthMetrics;
